import { useEffect, useState } from "react";
import { useMutationLogin, useQueryGetTenant } from "../../hooks/useQuery";
import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { tokenAtom } from "../../util/atoms";

function LoginPage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [idError, setIdError] = useState(false); // IDエラーステート
  const [passwordError, setPasswordError] = useState(false); // パスワードエラーステート
  const navigate = useNavigate();
  const setToken = useSetAtom(tokenAtom);
  const loginMutation = useMutationLogin();
  const { isLoading, isError } = useQueryGetTenant();

  const handleSubmit = async () => {
    try {
      const { token } = await loginMutation.mutateAsync({
        loginId: id,
        password: password,
      });
      setToken(token);
      navigate("/");
    } catch (e) {
      console.error(e);
      // エラーハンドリング: フォームの枠を赤くし、エラーメッセージを表示
      setIdError(true);
      setPasswordError(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sharedProps = {
    id,
    setId,
    password,
    setPassword,
    idError,
    setIdError,
    passwordError,
    setPasswordError,
    handleSubmit,
    isLoading,
    isError,
  };

  return isMobile ? (
    <MobileLoginPage {...sharedProps} />
  ) : (
    <DesktopLoginPage {...sharedProps} />
  );
}

interface LoginPageProps {
  id: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  idError: boolean;
  setIdError: React.Dispatch<React.SetStateAction<boolean>>;
  passwordError: boolean;
  setPasswordError: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
  isError: boolean;
}

const MobileLoginPage: React.FC<LoginPageProps> = ({
  id,
  setId,
  password,
  setPassword,
  idError,
  setIdError,
  passwordError,
  handleSubmit,
  isLoading,
  isError,
  setPasswordError,
}) => {
  if (isLoading) {
    return <></>;
  }

  if (isError) {
    return (
      <div className="flex flex-col justify-center items-center h-screen p-16px bg-#F3F4F6">
        <div className="flex flex-col gap-24px max-w-[318px] h-[260px] px-20px  py-36px rounded-16px border border-[rgba(209,213,219,0.5)] bg-white border-solid border-1px border-lightGray">
          <div className="flex gap-5.16px mx-auto">
            <div className="leading-7">
              <img src="/mobile_login_title.svg" alt="Logo" />
            </div>
            <p className="my-0 text-secondary text-[rgba(55,65,81,0.8)] font-hiragino text-[17.216px] font-normal leading-6.5">
              フードロスクーポン
            </p>
          </div>
          <div className="text-16px text-center">ショップ用コンソール</div>
          <p className="mx-auto text-gray-900 font-hiragino text-[14px] leading-5">
            URLが無効です。管理者に問い合わせて正しいURLを受け取ってください。
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen bg-#F3F4F6">
        <div className="flex flex-col gap-24px max-w-[318px] px-20px py-36px rounded-16px border border-[rgba(209,213,219,0.5)] bg-white border-solid border-1px border-lightGray">
          <div className="flex gap-5.16px mx-auto">
            <div className="leading-7">
              <img src="/mobile_login_title.svg" alt="Logo" />
            </div>
            <p className="my-0 text-secondary text-[rgba(55,65,81,0.8)] font-hiragino text-[17.216px] font-normal leading-6.5">
              フードロスクーポン
            </p>
          </div>
          <div className="text-16px text-center">ショップ用コンソール</div>
          <div className="flex flex-col gap-[4px]">
            <p className="m-0 text-[16px] text-#111827 font-hiragino">ID</p>
            <input
              className={`h-[16px] py-[16px] px-[14px] appearance-none border-[1px] border-solid rounded-[12px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText ${
                idError ? "border-[2px] border-#B91C1C" : "border-lightGray"
              }`} // エラーステートに応じて枠の色を変える
              type="text"
              placeholder="IDを入力してください"
              value={id}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setId(e.target.value);
                setIdError(false); // 入力時にエラーメッセージをリセット
              }}
            />
            {idError && ( // エラーメッセージの表示
              <p className="m-0 text-#B91C1C text-[14px]">
                IDが正しくありません
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[4px]">
            <p className="m-0 text-[16px] text-#111827 font-hiragino">
              パスワード
            </p>
            <input
              className={`h-[16px] py-[16px] px-[14px] appearance-none border-[1px] border-solid rounded-[12px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText ${
                passwordError
                  ? "border-[2px] border-#B91C1C"
                  : "border-lightGray"
              }`} // エラーステートに応じて枠の色を変える
              type="password" // パスワード用のインプットフィールド
              placeholder="パスワードを入力してください"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
                setPasswordError(false); // 入力時にエラーメッセージをリセット
              }}
            />
            {passwordError && ( // エラーメッセージの表示
              <p className="m-0 text-#B91C1C text-[14px]">
                パスワードが正しくありません
              </p>
            )}
          </div>
          <button
            type="button"
            className={`w-full h-[48px] mx-auto px-[28px] py-[12px] border-0 text-white shadow-ButtonShadow font-[16px] rounded-[12px] ${
              id === "" || password === ""
                ? "bg-[#E4E4E7] cursor-not-allowed text-disableText" // 非活性時のスタイル
                : "bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer" // 通常時のスタイル
            }`}
            onClick={handleSubmit}
          >
            ログインする
          </button>
        </div>
      </div>
    </>
  );
};

const DesktopLoginPage: React.FC<LoginPageProps> = ({
  id,
  setId,
  password,
  setPassword,
  idError,
  setIdError,
  passwordError,
  handleSubmit,
  isLoading,
  isError,
  setPasswordError,
}) => {
  if (isLoading) {
    return <></>;
  }

  if (isError) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-#F3F4F6 font-hiragino">
        <div className="flex flex-col gap-24px w-[433px] h-[260px] p-64px rounded-16px border border-[rgba(209,213,219,0.5)] bg-white border-solid border-1px border-lightGray">
          <div className="flex gap-8px mx-auto">
            <div className="w-[184.661px] h-[24.167px] leading-13.5">
              <img src="/login_title.svg" alt="Logo" />
            </div>
            <p className="my-0 text-secondary text-[rgba(55,65,81,0.8)] font-hiragino text-[26.667px] font-normal leading-[40px]">
              フードロスクーポン
            </p>
          </div>
          <div className="text-18px text-center font-semibold">
            ショップ用コンソール
          </div>
          <p className="mx-auto text-gray-900 font-hiragino font-[16px]">
            URLが無効です。管理者に問い合わせて正しいURLを受け取ってください。
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen bg-#F3F4F6 font-hiragino">
        <div className="flex flex-col gap-[24px] w-[433px] h-[360px] p-[64px] rounded-[16px] border border-[rgba(209,213,219,0.5)] bg-white">
          <div className="flex gap-[8px]">
            <div className="w-[184.661px] h-[24.167px] leading-11">
              <img src="/login_title.svg" alt="Logo" />
            </div>
            <p className="m-0  w-[240px] h-[40px] text-[26.667px] text-secondary  font-hiragino  font-normal">
              フードロスクーポン
            </p>
          </div>
          <p className="my-0 mx-auto w-[180px] h-[24px] text-center text-[18px] font-hiragino font-semibold">
            ショップ用コンソール
          </p>
          <div className="flex flex-col gap-[4px]">
            <p className="m-0 text-[16px] text-#111827 font-hiragino">ID</p>
            <input
              className={`w-[405px] h-[16px] py-[16px] px-[14px] appearance-none border-[1px] border-solid rounded-[12px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText ${
                idError ? "border-[2px] border-#B91C1C" : "border-lightGray"
              }`} // エラーステートに応じて枠の色を変える
              type="text"
              placeholder="IDを入力してください"
              value={id}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setId(e.target.value);
                setIdError(false); // 入力時にエラーメッセージをリセット
              }}
            />
            {idError && ( // エラーメッセージの表示
              <p className="m-0 text-#B91C1C text-[14px]">
                IDが正しくありません
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[4px]">
            <p className="m-0 text-[16px] text-#111827 font-hiragino">
              パスワード
            </p>
            <input
              className={`w-[405px] h-[16px] py-[16px] px-[14px] appearance-none border-[1px] border-solid rounded-[12px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText ${
                passwordError
                  ? "border-[2px] border-#B91C1C"
                  : "border-lightGray"
              }`} // エラーステートに応じて枠の色を変える
              type="password" // パスワード用のインプットフィールド
              placeholder="パスワードを入力してください"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
                setPasswordError(false); // 入力時にエラーメッセージをリセット
              }}
            />
            {passwordError && ( // エラーメッセージの表示
              <p className="m-0 text-#B91C1C text-[14px]">
                パスワードが正しくありません
              </p>
            )}
          </div>
          <button
            type="button"
            style={{
              WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
              outline: "none", // タップ時のアウトラインを無効化
            }}
            className={`w-[433px] h-[48px] mx-auto px-[28px] py-[12px] border-0 text-white shadow-ButtonShadow font-[16px] rounded-[12px] ${
              id === "" || password === ""
                ? "bg-[#E4E4E7] cursor-not-allowed text-disableText" // 非活性時のスタイル
                : "bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer" // 通常時のスタイル
            }`}
            onClick={handleSubmit}
          >
            ログインする
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
