/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryKey } from "@tanstack/react-query";
import type { PromiseClient } from "@connectrpc/connect";
import type {
  MethodInfo,
  PartialMessage,
  ServiceType,
} from "@bufbuild/protobuf";

type WithTQueryHelpers<T extends ServiceType> = {
  [P in keyof PromiseClient<T>]: WithTQueryHelpersSingle<
    ServiceType["typeName"],
    P,
    PromiseClient<T>[P],
    T["methods"][P]
  >;
};
type WithTQueryHelpersSingle<N, P, Method, MethodI extends MethodInfo> =
  MethodI extends MethodInfo<infer I, any>
    ? Method & {
        readonly $partialQueryKey: readonly [N, P];
        $getQueryKey(
          input:
            | PartialMessage<I>
            | {
                [K in keyof PartialMessage<I>]: React.MutableRefObject<
                  PartialMessage<I>[K]
                >;
              }
        ): QueryKey;
      }
    : never;

export const withTQueryHelpers = <T extends ServiceType>(
  typeName: T["typeName"],
  promiseClient: PromiseClient<T>
): WithTQueryHelpers<T> => {
  const result = {} as any;
  for (const [key, value] of Object.entries(promiseClient)) {
    result[key] = withTQueryHelpersSingle(
      typeName,
      key,
      value as PromiseClient<ServiceType>[string]
    );
  }
  return result;
};

const withTQueryHelpersSingle = <
  T extends ServiceType,
  P extends keyof ServiceType["methods"],
  Method extends PromiseClient<ServiceType>[P],
>(
  typeName: T["typeName"],
  key: P,
  value: Method
): WithTQueryHelpersSingle<
  T["typeName"],
  P,
  Method,
  ServiceType["methods"][P]
> => {
  // @ts-expect-error clone function
  const cloned: Method = (...args: any[]) => value(...args);

  const f = cloned as WithTQueryHelpersSingle<
    T["typeName"],
    P,
    Method,
    ServiceType["methods"][P]
  >;
  // reactiveの対応必要か？
  // f.$partialQueryKey = readonly(reactive([typeName, key]))
  // @ts-expect-error partialQueryKeyはreadOnlyだが、ここは初期化処理なので代入エラーは無視する
  f.$partialQueryKey = Object.freeze([typeName, key]);
  // f.$getQueryKey = (input) => readonly(reactive([typeName, key, input || {}]))
  f.$getQueryKey = (input) => Object.freeze([typeName, key, input || {}]);
  return f;
};
