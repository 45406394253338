import { useEffect, useState } from "react";

function Toast({ message, onClose }: { message: string; onClose: () => void }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // 少し遅らせてスライドインさせる
    const showTimeout = setTimeout(() => setIsVisible(true), 50);

    // 3秒後にスライドアウトさせる
    const hideTimeout = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 500); // 退場アニメーション後にonCloseを呼ぶ
    }, 3000);

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, [onClose]);

  return (
    <div
      className={`fixed top-15 right-4 flex w-[318px] h-[24px] p-[16px] bg-#FFF rounded-[16px] shadow-DropShadowHight transition-transform transition-opacity duration-500 ease-in-out ${
        isVisible ? "translate-x-0 opacity-100" : "translate-x-full"
      }`}
    >
      <div className="flex gap-[8px]">
        <div>
          <img src="/check_circle_fill.svg" alt="check" />
        </div>
        <p className="m-0 leading-6">{message}</p>
      </div>
    </div>
  );
}

export default Toast;
