import { useEffect, useRef, useState } from "react";
import {
  useQueryGetTemplateCouponsByShopID,
  useMutationDistributeCoupon,
  useMutationDeleteTemplateCoupon,
  useQueryGetShopByID,
} from "../../hooks/useQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import "dayjs/locale/ja";
import { jaJP } from "@mui/x-date-pickers/locales";
import dayjs, { Dayjs } from "dayjs";
import { PlainMessage, Timestamp } from "@bufbuild/protobuf";
import { dayjsToTimestamp, formatDate } from "../../util/date";
import {
  GetShopByIDResponse,
  GetTemplateCouponsByShopIDResponse,
} from "schema/gen/es/foodlosscoupon/shopconsole/v1/shopconsole_pb";

const CouponListPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号
  const limit = 20; // 1ページに表示する件数
  const { data: getTemplateCouponsByShopID, refetch } =
    useQueryGetTemplateCouponsByShopID(currentPage, limit);
  const { data: getShopByID } = useQueryGetShopByID();
  const distributeCouponMutation = useMutationDistributeCoupon();
  const deleteTemplateCoupon = useMutationDeleteTemplateCoupon();
  const [isCouponSettingModalOpen, setIsCouponSettingModalOpen] =
    useState(false);
  const [isAfterCreateCouponMOdalOpen, setIsAfterCreateCouponMOdalOpen] =
    useState(false);
  const [isStartDateTimeFormOpen, setIsStartDateTimeFormOpen] = useState(false);
  const [isEndDateTimeFormOpen, setIsEndDateTimeFormOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState<Dayjs | null>(null);
  const [endDateTime, setEndDateTime] = useState<Dayjs | null>(null);
  const [remarks, setRemarks] = useState("");
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState<number | null>(null); // メニューの開閉状態
  const [menuPosition, setMenuPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 }); // ポップアップの位置
  const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);
  const menuRef = useRef<HTMLDivElement | null>(null); // メニューの参照
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [endDateTimeError, setEndDateTimeError] = useState("");

  const toggleMenu = (index: number) => {
    if (buttonRefs.current[index]) {
      const rect = buttonRefs.current[index]?.getBoundingClientRect();
      setMenuPosition({
        top: (rect?.bottom ?? 0) + window.scrollY, // スクロール位置を加味
        left: (rect?.left ?? 0) + window.scrollX, // スクロール位置を加味
      });
    }
    setIsMenuOpen((prev) => (prev === index ? null : index)); // クリックされた行のメニューを開く・閉じる
  };

  const openCouponSettingModal = (id: string) => {
    setSelectedCouponId(id);
    setIsCouponSettingModalOpen(true);
  };

  const openDeleteModal = (id: string) => {
    setSelectedCouponId(id);
    setIsDeleteModalOpen(true);
  };

  const closeCouponSettingModal = () => {
    setIsStartDateTimeFormOpen(false);
    setIsEndDateTimeFormOpen(false);
    setIsCouponSettingModalOpen(false);
    setStartDateTime(null);
    setEndDateTime(null);
    setRemarks("");
    setSelectedCouponId("");
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).id === "modal-overlay") {
      closeCouponSettingModal();
    }
  };

  const handleStartDateTimeRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDateTime(null);
    setIsStartDateTimeFormOpen(e.target.value === "withDate");

    // 配布開始日時を「設定しない」に戻した場合、エラーメッセージを消去
    if (e.target.value === "withoutDate") {
      setEndDateTimeError("");
    }
  };

  const handleEndDateTimeRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEndDateTime(null);
    setIsEndDateTimeFormOpen(e.target.value === "withDate");

    // 配布終了日時を「設定しない」に戻した場合、エラーメッセージを消去
    if (e.target.value === "withoutDate") {
      setEndDateTimeError("");
    }
  };
  const handleStartDateTimeChange = (newValue: Dayjs | null) => {
    setStartDateTime(newValue);

    // 開始日時を変更した際にバリデーションを確認
    if (endDateTime && newValue && newValue.isAfter(endDateTime)) {
      setEndDateTimeError(
        "配布開始日時は配布終了日時より前に設定してください。"
      );
    } else {
      setEndDateTimeError("");
    }
  };

  const handleEndDateTimeChange = (newValue: Dayjs | null) => {
    setEndDateTime(newValue);

    // 終了日時が開始日時より前の場合のバリデーション
    if (startDateTime && newValue && newValue.isBefore(startDateTime)) {
      setEndDateTimeError(
        "配布終了日時は配布開始日時より後に設定してください。"
      );
    } else {
      setEndDateTimeError(""); // エラーがなければエラーメッセージをクリア
    }
  };

  const isOkButtonDisabled = () => {
    if (
      isStartDateTimeFormOpen &&
      isEndDateTimeFormOpen &&
      (!startDateTime || !endDateTime)
    ) {
      return true;
    }
    if (isStartDateTimeFormOpen && !startDateTime) {
      return true;
    }
    if (isEndDateTimeFormOpen && !endDateTime) {
      return true;
    }
    if (endDateTimeError) {
      return true; // エラーがある場合はボタンを無効化
    }
    return false;
  };

  const handleDistributionSubmit = async () => {
    const parsedStartDateTime = startDateTime
      ? dayjsToTimestamp(startDateTime)
      : dayjsToTimestamp(dayjs()); // 配布開始日時が未設定の場合、現在時刻を配布開始日時として設定
    const parsedEndDateTime = dayjsToTimestamp(endDateTime);

    // バリデーションチェック
    if (!startDateTime && endDateTime && endDateTime.isBefore(dayjs())) {
      setEndDateTimeError(
        "配布開始日時を設定しない場合は配布終了日時は現在の時刻より後に設定してください。"
      );
      return; // バリデーションエラーがある場合は処理を中断
    }

    try {
      // バリデーションを通過した場合のみバックエンドに送信
      const distributeCoupon = await distributeCouponMutation.mutateAsync({
        coupon: {
          id: selectedCouponId,
          distributionStartAt: parsedStartDateTime,
          distributionEndAt: parsedEndDateTime,
          remarks: remarks,
        },
      });
      closeCouponSettingModal();
      navigate("/distributed_coupons", {
        state: { distributedCouponID: distributeCoupon.coupon?.id },
      });
    } catch (error) {
      console.error("Error distributing coupon:", error);
    }
  };
  const handleDeleteSubmit = () => {
    deleteTemplateCoupon.mutateAsync({
      id: selectedCouponId,
    });
    setSelectedCouponId("");
    setIsDeleteModalOpen(false);
  };

  const nextPage = () => {
    if (
      getTemplateCouponsByShopID &&
      getTemplateCouponsByShopID.total > currentPage * limit
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // ページを戻す処理
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const sharedProps = {
    currentPage,
    selectedCouponId,
    setSelectedCouponId,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isCouponSettingModalOpen,
    setIsCouponSettingModalOpen,
    isAfterCreateCouponMOdalOpen,
    setIsAfterCreateCouponMOdalOpen,
    startDateTime,
    endDateTime,
    endDateTimeError,
    setEndDateTimeError,
    remarks,
    setRemarks,
    menuRef,
    menuPosition,
    setMenuPosition,
    toggleMenu,
    isMenuOpen,
    setIsMenuOpen,
    handleStartDateTimeChange,
    handleEndDateTimeChange,
    handleDistributionSubmit,
    handleDeleteSubmit,
    navigate,
    getTemplateCouponsByShopID,
    getShopByID,
    openCouponSettingModal,
    openDeleteModal,
    handleOverlayClick,
    handleStartDateTimeRadioChange,
    handleEndDateTimeRadioChange,
    isOkButtonDisabled,
    nextPage,
    prevPage,
    closeCouponSettingModal,
    limit,
    isStartDateTimeFormOpen,
    buttonRefs,
    isEndDateTimeFormOpen,
  };

  useEffect(() => {
    window.scrollTo(0, 0); // スクロール位置を一番上にリセット
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.state && location.state.createdCouponID) {
      setSelectedCouponId(location.state.createdCouponID);
      setIsAfterCreateCouponMOdalOpen(true);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRefs.current.some((ref) => ref?.contains(event.target as Node))
      ) {
        setIsMenuOpen(null); // ポップアップを非表示に
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    refetch(); // currentPageやlimitが変わったらデータを再取得する
  }, [currentPage, limit, refetch]);

  return isMobile ? (
    <MobileLoginPage {...sharedProps} />
  ) : (
    <DesktopLoginPage {...sharedProps} />
  );
};

interface CouponListProps {
  currentPage: number;
  selectedCouponId: string;
  setSelectedCouponId: React.Dispatch<React.SetStateAction<string>>;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCouponSettingModalOpen: boolean;
  isAfterCreateCouponMOdalOpen: boolean;
  setIsAfterCreateCouponMOdalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  startDateTime: Dayjs | null;
  endDateTime: Dayjs | null;
  endDateTimeError: string;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  menuRef: React.RefObject<HTMLDivElement>;
  menuPosition: { top: number; left: number };
  toggleMenu: (index: number) => void;
  isMenuOpen: number | null;
  handleStartDateTimeChange: (newValue: Dayjs | null) => void;
  handleEndDateTimeChange: (newValue: Dayjs | null) => void;
  handleDistributionSubmit: () => Promise<void>;
  handleDeleteSubmit: () => void;
  navigate: (path: string, state?: any) => void;
  getTemplateCouponsByShopID?:
    | PlainMessage<GetTemplateCouponsByShopIDResponse>
    | undefined;
  getShopByID: PlainMessage<GetShopByIDResponse> | undefined;
  openCouponSettingModal: (id: string) => void;
  openDeleteModal: (id: string) => void;
  handleOverlayClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleStartDateTimeRadioChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleEndDateTimeRadioChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  isOkButtonDisabled: () => boolean;
  nextPage: () => void;
  prevPage: () => void;
  closeCouponSettingModal: () => void;
  limit: number;
  isStartDateTimeFormOpen: boolean;
  buttonRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  isEndDateTimeFormOpen: boolean;
}

const MobileLoginPage: React.FC<CouponListProps> = ({
  currentPage,
  selectedCouponId,
  setSelectedCouponId,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isCouponSettingModalOpen,
  isAfterCreateCouponMOdalOpen,
  setIsAfterCreateCouponMOdalOpen,
  startDateTime,
  endDateTime,
  endDateTimeError,
  remarks,
  setRemarks,
  menuRef,
  menuPosition,
  toggleMenu,
  isMenuOpen,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  handleDistributionSubmit,
  handleDeleteSubmit,
  navigate,
  getTemplateCouponsByShopID,
  getShopByID,
  openCouponSettingModal,
  openDeleteModal,
  handleOverlayClick,
  handleStartDateTimeRadioChange,
  handleEndDateTimeRadioChange,
  isOkButtonDisabled,
  nextPage,
  prevPage,
  closeCouponSettingModal,
  limit,
  isStartDateTimeFormOpen,
  buttonRefs,
  isEndDateTimeFormOpen,
}) => {
  return (
    <>
      <div className="p-[16px]">
        <div className="flex flex-col gap-16px">
          <h2 className="m-0 text-[20px] text-#111827 font-semibold leading-7 font-hiragino">
            クーポン一覧
          </h2>
          {getShopByID?.shop?.address ? (
            <button
              type="button"
              style={{
                WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                outline: "none", // タップ時のアウトラインを無効化
              }}
              onClick={() => navigate("/create_template_coupon")}
              className="block flex gap-[2px] w-[188px] h-[34px] py-[8px] px-[12px] rounded-[12px] bg-#001EFB text-[14px] font-medium font-hiragino border-0 text-white hover:bg-#334BFC hover:cursor-pointer focus:outline-none"
            >
              <div>
                <span className="material-symbols-rounded  text-[20px]">
                  add
                </span>
              </div>
              <div className="leading-5">新しいクーポンを登録</div>
            </button>
          ) : (
            <div className="flex flex-col gap-16px p-16px bg-warnLight rounded-12px border-solid border-1px border-lightGray">
              <div className="flex gap-4px">
                <div>
                  <span className="material-symbols-rounded text-24px text-#CA8A04">
                    warning
                  </span>
                </div>
                <div className="text-16px text-#111827 font-hiragino leading-6">
                  ショップ設定完了後にクーポンの登録が可能になります
                </div>
              </div>
              <button
                style={{
                  WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                  outline: "none", // タップ時のアウトラインを無効化
                }}
                onClick={() => navigate("/shop_setting")}
                type="button"
                className="block ml-auto w-[154px] h-[34px] py-[8px] px-[12px] rounded-[8px] bg-#FFF border-none hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
              >
                <div className="text-14px font-semibold text-#111827 font-hiragino leading-4.5">
                  ショップ設定を開く
                </div>
              </button>
            </div>
          )}
          <div className="flex flex-col gap-4px">
            <div className="bg-white rounded-t-[16px]">
              <div className="flex justify-end h-[54px] px-[24px] border-b-[1px] border-b-lightGray border-b-solid">
                <div className="flex gap-[16px] w-[130px]">
                  <p className="w-[62px] my-0 text-[#111827] text-[12px] leading-14">
                    {currentPage}/
                    {Math.ceil(
                      (getTemplateCouponsByShopID?.total || 1) / limit
                    )}
                    ページ
                  </p>
                  <div className="flex gap-[4px] pt-[15px]">
                    <div
                      style={{
                        WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                        outline: "none", // タップ時のアウトラインを無効化
                      }}
                      onClick={prevPage}
                    >
                      <span className="material-symbols-rounded text-[24px] rounded-999  hover:cursor-pointer">
                        arrow_back
                      </span>
                    </div>
                    <div
                      style={{
                        WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                        outline: "none", // タップ時のアウトラインを無効化
                      }}
                      onClick={nextPage}
                    >
                      <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                        arrow_forward
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-b-[16px]">
              {getTemplateCouponsByShopID?.coupons.map((coupon, index) => {
                return (
                  <div
                    key={index}
                    className={`flex flex-col gap-12px h-[200px] p-[20px] gap-[12px] ${index === getTemplateCouponsByShopID?.coupons.length - 1 ? "rounded-b-[16px]" : ""} border-b-[1px] border-b-lightGray border-b-solid`}
                  >
                    <div className="flex justify-between h-42px">
                      <div className="text-12px text-secondary">商品画像</div>
                      <div>
                        {coupon.imageBase64 ? (
                          <img
                            src={coupon.imageBase64}
                            alt={coupon.couponName}
                            className="w-72px h-42px rounded-4px border-solid border-1px border-lightGray" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                          />
                        ) : (
                          <p>image</p>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between h-16px">
                      <div className="text-12px text-secondary">商品名</div>
                      <div className="text-12px text-#111827">
                        {coupon.couponName}
                      </div>
                    </div>
                    <div className="flex justify-between h-16px">
                      <div className="text-12px text-secondary">割引</div>
                      <div className="text-12px text-#111827">
                        {coupon.discountMethod == "percentage_discount"
                          ? coupon.discountValue + "%OFF"
                          : coupon.discountValue + "円引き"}
                      </div>
                    </div>
                    <div className="flex justify-between h-16px">
                      <div className="text-12px text-secondary">価格</div>
                      <div className="text-12px text-#111827">
                        {coupon.isSetOriginalPrice
                          ? coupon.discountMethod === "percentage_discount"
                            ? // パーセント割引の場合
                              `${Math.round(Number(coupon.originalPrice) * (1 - Number(coupon.discountValue) / 100))}円`
                            : // 固定金額割引の場合
                              `${Math.max(Number(coupon.originalPrice) - Number(coupon.discountValue), 0)}円`
                          : "-"}
                      </div>
                    </div>
                    <div className="flex justify-between h-16px">
                      <div className="text-12px text-secondary">追加日</div>
                      <div className="text-12px text-#111827">
                        {formatDate(
                          (coupon.createdAt as Timestamp)?.toDate() ??
                            new Date(0),
                          "yyyy/MM/dd(ww)"
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between h-34px">
                      <button
                        type="button"
                        style={{
                          WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                          outline: "none", // タップ時のアウトラインを無効化
                        }}
                        onClick={() => openCouponSettingModal(coupon.id)}
                        className="block flex gap-[2px] w-[108px] h-[36px] py-[8px] px-[12px] rounded-[8px] bg-#F8FAFC border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
                      >
                        <div>
                          <span className="material-symbols-rounded text-[20px text-#111827">
                            send
                          </span>
                        </div>
                        <div className="text-#111827 text-[14px] font-medium font-hiragino leading-5.2">
                          配布設定
                        </div>
                      </button>
                      <div
                        onClick={() => toggleMenu(index)}
                        style={{
                          WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                          outline: "none", // タップ時のアウトラインを無効化
                        }}
                        ref={(el) => (buttonRefs.current[index] = el)} // ボタンの参照を設定
                        className="w-[24px]"
                      >
                        <span className="pt-5px material-symbols-rounded text-[24px]">
                          more_horiz
                        </span>
                      </div>
                    </div>
                    {isMenuOpen === index && (
                      <div
                        ref={menuRef}
                        style={{
                          position: "absolute",
                          top: `${menuPosition.top}px`,
                          left: `${menuPosition.left - 75}px`,
                        }}
                        className="z-10 w-[112px] h-[64px] p-[4px] bg-white rounded-[8px] border-[1px] border-solid border-lightGray shadow-DropShadowLow"
                      >
                        <ul className="m-0 p-0">
                          <li
                            style={{
                              WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                              outline: "none", // タップ時のアウトラインを無効化
                            }}
                            onClick={() =>
                              navigate(`update_template_coupon/${coupon.id}`)
                            }
                            className="flex gap-[2px] w-[92px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                          >
                            <div>
                              <span className="material-symbols-rounded text-[20px]">
                                edit
                              </span>
                            </div>
                            <div className="w-[70px] h-[12px] text-#111827 font-hiragino leading-5">
                              編集
                            </div>
                          </li>
                          <li
                            style={{
                              WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                              outline: "none", // タップ時のアウトラインを無効化
                            }}
                            onClick={() => openDeleteModal(coupon.id)}
                            className="flex gap-[2px] w-[92px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                          >
                            <div>
                              <span className="material-symbols-rounded text-[20px] text-#B91C1C">
                                delete
                              </span>
                            </div>
                            <div className="w-[70px] h-[12px] text-#B91C1C font-hiragino leading-5">
                              削除
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {getTemplateCouponsByShopID?.coupons.length == 0 && (
              <div className="flex flex-col gap-8px mt-4px h-136px py-48px ">
                <div className="flex justify-center">
                  <span className="material-symbols-rounded text-[104px] text-#9CA3AF80">
                    confirmation_number
                  </span>
                </div>
                <div className="text-16px text-tertiary text-center leading-5">
                  クーポンはありません
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isCouponSettingModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 left-0 z-50 flex justify-center items-center box-border w-full h-screen px-20px backdrop-blur-sm bg-surfaceOverlay"
        >
          <div onClick={(e) => e.stopPropagation()} className="relative">
            <div
              className={`relative flex flex-col items-start gap-[20px] h-[83vh] overflow-y-auto p-[20px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow`}
            >
              <h3 className="text-[18px] m-0 font-semibold text-#111827 font-hiragino">
                クーポンを配布
              </h3>
              <div className="flex flex-col gap-8px">
                <div className="">
                  <div></div>
                  <label
                    className="flex flex-col gap-[8px]"
                    htmlFor="originPrice"
                  >
                    <div className="flex gap-[8px]">
                      <p className="m-0 text-[16px] text-#111827 font-hiragino">
                        配布開始日時設定
                      </p>
                      <p className="m-0 text-[14px] text-#B91C1C font-hiragino leading-5">
                        必須
                      </p>
                    </div>
                    <p className="m-0 text-14px font-hiragino text-secondary">
                      「設定しない」を選択した場合、即時公開されます
                    </p>
                  </label>
                  <form className="flex mt-[8px]">
                    <div className="flex items-center">
                      <input
                        id="inline-1-radio"
                        type="radio"
                        value="withoutDate"
                        name="inline-radio-group"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        onChange={handleStartDateTimeRadioChange}
                        defaultChecked
                      />
                      <label
                        htmlFor="inline-1-radio"
                        className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                      >
                        設定しない
                      </label>
                    </div>
                    <div className="flex items-center ml-[8px]">
                      <input
                        id="inline-2-radio"
                        type="radio"
                        value="withDate"
                        name="inline-radio-group"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        onChange={handleStartDateTimeRadioChange}
                      />
                      <label
                        htmlFor="inline-2-radio"
                        className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                      >
                        設定する
                      </label>
                    </div>
                  </form>
                  {isStartDateTimeFormOpen && (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="ja"
                      localeText={{
                        ...jaJP.components.MuiLocalizationProvider.defaultProps
                          .localeText,
                        cancelButtonLabel: "キャンセル",
                        okButtonLabel: "OK",
                      }}
                    >
                      <DemoContainer components={["MobileDateTimePicker"]}>
                        <MobileDateTimePicker
                          label="配布開始日時を指定してください"
                          ampm={false}
                          value={startDateTime}
                          onChange={handleStartDateTimeChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                </div>
                <div className="">
                  <label
                    className="flex flex-col gap-[8px]"
                    htmlFor="originPrice"
                  >
                    <div className="flex gap-[8px]">
                      <p className="m-0 text-[16px] text-#111827 font-hiragino">
                        配布終了日時設定
                      </p>
                      <p className="m-0 text-[14px] text-#B91C1C font-hiragino leading-5">
                        必須
                      </p>
                    </div>
                    <p className="m-0 text-14px font-hiragino text-secondary">
                      「設定しない」を選択した場合、中止をするまで公開され続けます
                    </p>
                  </label>
                  <form className="flex mt-[8px]">
                    <div className="flex items-center">
                      <input
                        id="inline-3-radio"
                        type="radio"
                        value="withoutDate"
                        name="inline-radio-group"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                        onChange={handleEndDateTimeRadioChange}
                        defaultChecked
                      />
                      <label
                        htmlFor="inline-3-radio"
                        className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                      >
                        設定しない
                      </label>
                    </div>
                    <div className="flex items-center ml-[8px]">
                      <input
                        id="inline-4-radio"
                        type="radio"
                        value="withDate"
                        name="inline-radio-group"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        onChange={handleEndDateTimeRadioChange}
                      />
                      <label
                        htmlFor="inline-4-radio"
                        className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                      >
                        設定する
                      </label>
                    </div>
                  </form>
                  {isEndDateTimeFormOpen && (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="ja"
                      localeText={{
                        ...jaJP.components.MuiLocalizationProvider.defaultProps
                          .localeText,
                        cancelButtonLabel: "キャンセル",
                        okButtonLabel: "OK",
                      }}
                    >
                      <DemoContainer components={["MobileDateTimePicker"]}>
                        <MobileDateTimePicker
                          label="配布終了日時を指定してください"
                          ampm={false}
                          value={endDateTime}
                          onChange={handleEndDateTimeChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                  {endDateTimeError && (
                    <p className="text-red-500 text-sm mt-1">
                      {endDateTimeError}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-8px">
                  <label
                    className="flex flex-col gap-[8px]"
                    htmlFor="originPrice"
                  >
                    <div className="flex gap-[8px]">
                      <p className="m-0 text-[16px] text-#111827 font-hiragino">
                        備考
                      </p>
                      <p className="m-0 text-[14px] text-tertiary font-hiragino leading-5">
                        任意
                      </p>
                    </div>
                    <p className="m-0 text-14px font-hiragino text-secondary">
                      賞味期限や消費期限など、このクーポン配布で伝えたい内容があればこちらに記載してください
                    </p>
                  </label>
                  <textarea
                    className="box-border h-[130px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[12px] px-[14px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                    id="couponName"
                    value={remarks}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setRemarks(e.target.value)
                    }
                    placeholder="例）賞味期限は1ヶ月ほどになります"
                  />
                </div>
              </div>
              <button
                type="button"
                style={{
                  WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                  outline: "none", // タップ時のアウトラインを無効化
                }}
                className={`block w-full h-[48px] py-[12px] px-[32px] text-[16px] font-medium font-hiragino border-none rounded-[12px]  ${isOkButtonDisabled() ? "bg-[#E4E4E7] text-disableText" : "bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer focus:ring-blue-300"}`}
                onClick={handleDistributionSubmit}
                disabled={isOkButtonDisabled()}
              >
                OK
              </button>
              <button
                type="button"
                style={{
                  WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                  outline: "none", // タップ時のアウトラインを無効化
                }}
                className="block w-full h-[48px] px-[28px] py-[12px] text-[16px] text-#111827 rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                onClick={closeCouponSettingModal}
              >
                キャンセル
              </button>
            </div>
          </div>
        </div>
      )}
      {isAfterCreateCouponMOdalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={() => {
            setSelectedCouponId("");
            setIsAfterCreateCouponMOdalOpen(false);
            navigate(location.pathname, { replace: true, state: {} });
          }}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[350px] h-[372px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[302px] h-[324px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <div className="flex flex-col gap-[20px]">
                <div>
                  <div className="text-center">
                    <span className="material-symbols-rounded text-[104px] text-#047857">
                      check_circle
                    </span>
                  </div>
                  <div className="text-16px text-center text-#111827 font-hiragino leading-8">
                    クーポンを登録しました
                  </div>
                </div>

                <div className="flex flex-col gap-12px">
                  <div className="text-14px text-#111827 font-hiragino leading-5">
                    まだ登録されたクーポンは配布されていません。配布設定に移りますか？
                  </div>
                  <button
                    type="button"
                    style={{
                      WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                      outline: "none", // タップ時のアウトラインを無効化
                    }}
                    className="block w-[302px] h-[48px] py-[12px] px-[24px] text-[16px] font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer focus:ring-blue-300" // 通常時のスタイル
                    onClick={() => {
                      setIsAfterCreateCouponMOdalOpen(false);
                      openCouponSettingModal(selectedCouponId);
                      navigate(location.pathname, { replace: true, state: {} });
                    }}
                  >
                    配布設定に移る
                  </button>
                  <button
                    type="button"
                    style={{
                      WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                      outline: "none", // タップ時のアウトラインを無効化
                    }}
                    className="block w-[302px] h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 bg-#F8FAFC rounded-[12px] font-semibold font-hiragino border-none focus:outline-none border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                    onClick={() => {
                      setSelectedCouponId("");
                      setIsAfterCreateCouponMOdalOpen(false);
                      navigate(location.pathname, { replace: true, state: {} });
                    }}
                  >
                    閉じる
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isDeleteModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={() => {
            setSelectedCouponId("");
            setIsDeleteModalOpen(false);
          }}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-full px-16px"
          >
            <div className="relative flex flex-col items-start gap-[20px] box-border w-full p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <div className="flex flex-col gap-[20px] mx-auto">
                <div className="text-18px text-#111827 text-medium font-hiragino">
                  このクーポンを削除しますか？
                </div>
                <div className="flex flex-col justify-center gap-12px">
                  <button
                    style={{
                      WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                      outline: "none", // タップ時のアウトラインを無効化
                    }}
                    type="button"
                    className="block mx-auto w-[210px] h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer focus:ring-blue-300" // 通常時のスタイル
                    onClick={handleDeleteSubmit}
                  >
                    はい
                  </button>
                  <button
                    type="button"
                    style={{
                      WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                      outline: "none", // タップ時のアウトラインを無効化
                    }}
                    className="block mx-auto w-[210px] h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 bg-#F8FAFC rounded-[12px] font-semibold font-hiragino border-none focus:outline-none border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                    onClick={() => {
                      setSelectedCouponId("");
                      setIsDeleteModalOpen(false);
                    }}
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DesktopLoginPage: React.FC<CouponListProps> = ({
  currentPage,
  selectedCouponId,
  setSelectedCouponId,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isCouponSettingModalOpen,
  isAfterCreateCouponMOdalOpen,
  setIsAfterCreateCouponMOdalOpen,
  startDateTime,
  endDateTime,
  endDateTimeError,
  remarks,
  setRemarks,
  menuRef,
  menuPosition,
  toggleMenu,
  isMenuOpen,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  handleDistributionSubmit,
  handleDeleteSubmit,
  navigate,
  getTemplateCouponsByShopID,
  getShopByID,
  openCouponSettingModal,
  openDeleteModal,
  handleOverlayClick,
  handleStartDateTimeRadioChange,
  handleEndDateTimeRadioChange,
  isOkButtonDisabled,
  nextPage,
  prevPage,
  closeCouponSettingModal,
  limit,
  isStartDateTimeFormOpen,
  buttonRefs,
  isEndDateTimeFormOpen,
}) => {
  return (
    <>
      <div className="p-[24px]">
        <div className="flex justify-between">
          <h2 className="m-0 text-[20px] font-semibold leading-10 font-hiragino">
            クーポン一覧
          </h2>
          {getShopByID?.shop?.address ? (
            <button
              type="button"
              onClick={() => navigate("/create_template_coupon")}
              className="block flex gap-[2px] w-[230px] h-[40px] py-[8px] pl-[20px] pr-[24px] rounded-[12px] bg-#001EFB text-[16px] font-medium font-hiragino border-0  text-white hover:bg-#334BFC hover:cursor-pointer focus:outline-none"
            >
              <div>
                <span className="material-symbols-rounded  text-[24px]">
                  add
                </span>
              </div>
              <div className="leading-6">新しいクーポンを登録</div>
            </button>
          ) : (
            <></>
          )}
        </div>
        {getShopByID?.shop?.address ? (
          <></>
        ) : (
          <div className="flex flex-col gap-16px mt-24px h-74px p-16px bg-warnLight rounded-12px border-solid border-1px border-lightGray">
            <div className="flex gap-4px">
              <div>
                <span className="material-symbols-rounded text-24px text-#CA8A04">
                  warning
                </span>
              </div>
              <div className="text-16px text-#111827 font-hiragino leading-6">
                ショップ設定完了後にクーポンの登録が可能になります
              </div>
            </div>
            <button
              onClick={() => navigate("/shop_setting")}
              type="button"
              className="block ml-auto w-[154px] h-[34px] py-[8px] px-[12px] rounded-[8px] bg-#FFF border-none hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
            >
              <div className="text-14px font-semibold text-#111827 font-hiragino leading-4.5">
                ショップ設定を開く
              </div>
            </button>
          </div>
        )}
        <div className="mt-[24px] bg-white rounded-t-[16px]">
          <div className="flex justify-end h-[54px] px-[24px] border-b-[1px] border-b-lightGray border-b-solid">
            <div className="flex gap-[16px] w-[130px]">
              <p className="w-[62px] my-0 text-[#111827] text-[12px] leading-14">
                {currentPage}/
                {Math.ceil((getTemplateCouponsByShopID?.total || 1) / limit)}
                ページ
              </p>
              <div className="flex gap-[4px] pt-[15px]">
                <div onClick={prevPage}>
                  <span className="material-symbols-rounded text-[24px] rounded-999  hover:cursor-pointer">
                    arrow_back
                  </span>
                </div>
                <div onClick={nextPage}>
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_forward
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-[48px] px-[24px] gap-[12px]">
            <p className="m-0 w-[72px] text-[12px] font-hiragino text-secondary leading-12">
              商品画像
            </p>
            <p className="m-0 w-[150px] text-[12px] font-hiragino text-secondary leading-12">
              商品名
            </p>
            <p className="m-0 w-[100px] text-[12px] font-hiragino text-secondary leading-12">
              割引
            </p>
            <p className="m-0 w-[120px] text-[12px] font-hiragino text-secondary leading-12">
              価格
            </p>
            <p className="m-0 w-[150px] text-[12px] font-hiragino text-secondary leading-12">
              追加日
            </p>
            <div className="ml-auto w-138px px-24px"></div>
          </div>
        </div>
        <div className="bg-white mt-4px rounded-b-[16px]">
          {getTemplateCouponsByShopID?.coupons.map((coupon, index) => {
            return (
              <div
                key={index}
                className={`flex h-[64px] px-[24px] gap-[12px] ${index === getTemplateCouponsByShopID?.coupons.length - 1 ? "rounded-b-[16px]" : ""} border-b-[1px] border-b-lightGray border-b-solid`}
              >
                <div className="w-72px">
                  {coupon.imageBase64 ? (
                    <img
                      src={coupon.imageBase64}
                      alt={coupon.couponName}
                      className="w-72px h-42px mt-10px rounded-4px border-solid border-1px border-lightGray" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                    />
                  ) : (
                    <p>image</p>
                  )}
                </div>
                <p className="w-[150px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {coupon.couponName}
                </p>
                <p className="w-[100px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {coupon.discountMethod == "percentage_discount"
                    ? coupon.discountValue + "%OFF"
                    : coupon.discountValue + "円引き"}
                </p>
                <p className="mt-[20px] w-[120px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] font-hiragino text-#111827">
                  {coupon.isSetOriginalPrice
                    ? coupon.discountMethod === "percentage_discount"
                      ? // パーセント割引の場合
                        `${Math.round(Number(coupon.originalPrice) * (1 - Number(coupon.discountValue) / 100))}円`
                      : // 固定金額割引の場合
                        `${Math.max(Number(coupon.originalPrice) - Number(coupon.discountValue), 0)}円`
                    : "-"}
                  <br />
                  {coupon.isSetOriginalPrice &&
                    `(定価${Number(coupon.originalPrice)}円)`}
                </p>
                <p className="w-[150px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {formatDate(
                    (coupon.createdAt as Timestamp)?.toDate() ?? new Date(0),
                    "yyyy/MM/dd(ww)"
                  )}
                </p>
                <div className="ml-auto w-138px px-24px border-l-solid border-l-1px border-l-lightGray shadow-SideShadow">
                  <div className="flex gap-8px mt-13px">
                    <button
                      type="button"
                      onClick={() => openCouponSettingModal(coupon.id)}
                      className="block flex gap-[2px] w-[106px] h-[36px] py-[8px] px-[12px] rounded-[8px] bg-#F8FAFC border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
                    >
                      <div>
                        <span className="material-symbols-rounded text-[20px] text-#111827">
                          send
                        </span>
                      </div>
                      <div className="text-#111827 text-[14px] font-medium font-hiragino leading-5.2">
                        配布設定
                      </div>
                    </button>
                    <div
                      onClick={() => toggleMenu(index)}
                      ref={(el) => (buttonRefs.current[index] = el)} // ボタンの参照を設定
                      className="w-[24px]"
                    >
                      <span className="material-symbols-rounded text-[24px] mt-5px cursor-pointer rounded-[9999px]">
                        more_vert
                      </span>
                    </div>
                  </div>
                </div>
                {isMenuOpen === index && (
                  <div
                    ref={menuRef}
                    style={{
                      position: "absolute",
                      top: `${menuPosition.top + 20}px`,
                      left: `${menuPosition.left - 53}px`,
                    }}
                    className="z-10 w-[112px] h-[64px] p-[4px] bg-white rounded-[8px] border-[1px] border-solid border-lightGray shadow-DropShadowLow"
                  >
                    <ul className="m-0 p-0">
                      <li
                        onClick={() =>
                          navigate(`update_template_coupon/${coupon.id}`)
                        }
                        className="flex gap-[2px] w-[92px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <div>
                          <span className="material-symbols-rounded text-[20px]">
                            edit
                          </span>
                        </div>
                        <div className="w-[70px] h-[12px] text-#111827 font-hiragino leading-5">
                          編集
                        </div>
                      </li>
                      <li
                        onClick={() => openDeleteModal(coupon.id)}
                        className="flex gap-[2px] w-[92px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <div>
                          <span className="material-symbols-rounded text-[20px] text-#B91C1C">
                            delete
                          </span>
                        </div>
                        <div className="w-[70px] h-[12px] text-#B91C1C font-hiragino leading-5">
                          削除
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {getTemplateCouponsByShopID?.coupons.length == 0 && (
          <div className="flex flex-col gap-8px mt-4px h-136px py-48px ">
            <div className="flex justify-center">
              <span className="material-symbols-rounded text-[104px] text-#9CA3AF80">
                confirmation_number
              </span>
            </div>
            <div className="text-16px text-tertiary text-center leading-5">
              クーポンはありません
            </div>
          </div>
        )}
      </div>
      {isCouponSettingModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[492px] h-[356px] mt-[-240px]"
          >
            <div
              className={`relative flex flex-col items-start gap-[20px]  w-[492px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow`}
            >
              <h3 className="text-[18px] m-0 font-semibold text-#111827 font-hiragino">
                クーポンを配布
              </h3>
              <div className="w-[492px]">
                <label
                  className="flex flex-col gap-[8px]"
                  htmlFor="originPrice"
                >
                  <div className="flex gap-[8px]">
                    <p className="m-0 text-[16px] text-#111827 font-hiragino">
                      配布開始日時設定
                    </p>
                    <p className="m-0 text-[14px] text-#B91C1C font-hiragino leading-5">
                      必須
                    </p>
                  </div>
                  <p className="m-0 text-14px font-hiragino text-secondary">
                    「設定しない」を選択した場合、即時公開されます
                  </p>
                </label>
                <form className="flex mt-[8px]">
                  <div className="flex items-center">
                    <input
                      id="inline-1-radio"
                      type="radio"
                      value="withoutDate"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={handleStartDateTimeRadioChange}
                      defaultChecked
                    />
                    <label
                      htmlFor="inline-1-radio"
                      className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                    >
                      設定しない
                    </label>
                  </div>
                  <div className="flex items-center ml-[8px]">
                    <input
                      id="inline-2-radio"
                      type="radio"
                      value="withDate"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={handleStartDateTimeRadioChange}
                    />
                    <label
                      htmlFor="inline-2-radio"
                      className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                    >
                      設定する
                    </label>
                  </div>
                </form>
                {isStartDateTimeFormOpen && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ja"
                  >
                    <DemoContainer components={["DesktopDateTimePicker"]}>
                      <DesktopDateTimePicker
                        label="配布開始日時を指定してください"
                        ampm={false}
                        value={startDateTime}
                        onChange={handleStartDateTimeChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
              </div>
              <div className="w-[492px]">
                <label
                  className="flex flex-col gap-[8px]"
                  htmlFor="originPrice"
                >
                  <div className="flex gap-[8px]">
                    <p className="m-0 text-[16px] text-#111827 font-hiragino">
                      配布終了日時設定
                    </p>
                    <p className="m-0 text-[14px] text-#B91C1C font-hiragino leading-5">
                      必須
                    </p>
                  </div>
                  <p className="m-0 text-14px font-hiragino text-secondary">
                    「設定しない」を選択した場合、中止をするまで公開され続けます
                  </p>
                </label>
                <form className="flex mt-[8px]">
                  <div className="flex items-center">
                    <input
                      id="inline-3-radio"
                      type="radio"
                      value="withoutDate"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onChange={handleEndDateTimeRadioChange}
                      defaultChecked
                    />
                    <label
                      htmlFor="inline-3-radio"
                      className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                    >
                      設定しない
                    </label>
                  </div>
                  <div className="flex items-center ml-[8px]">
                    <input
                      id="inline-4-radio"
                      type="radio"
                      value="withDate"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={handleEndDateTimeRadioChange}
                    />
                    <label
                      htmlFor="inline-4-radio"
                      className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                    >
                      設定する
                    </label>
                  </div>
                </form>
                {isEndDateTimeFormOpen && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ja"
                  >
                    <DemoContainer components={["DesktopDateTimePicker"]}>
                      <DesktopDateTimePicker
                        label="配布終了日時を指定してください"
                        ampm={false}
                        value={endDateTime}
                        onChange={handleEndDateTimeChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
                {endDateTimeError && (
                  <p className="text-red-500 text-sm mt-1">
                    {endDateTimeError}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-8px">
                <label
                  className="flex flex-col gap-[8px]"
                  htmlFor="originPrice"
                >
                  <div className="flex gap-[8px]">
                    <p className="m-0 text-[16px] text-#111827 font-hiragino">
                      備考
                    </p>
                    <p className="m-0 text-[14px] text-tertiary font-hiragino leading-5">
                      任意
                    </p>
                  </div>
                  <p className="m-0 text-14px font-hiragino text-secondary">
                    賞味期限や消費期限など、このクーポン配布で伝えたい内容があればこちらに記載してください
                  </p>
                </label>
                <textarea
                  className="w-494px box-border h-[130px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[12px] px-[14px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="couponName"
                  value={remarks}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setRemarks(e.target.value)
                  }
                  placeholder="例）賞味期限は1ヶ月ほどになります"
                />
              </div>
              <div className="flex justify-end w-[492px] h-[48px] gap-[12px]">
                <button
                  type="button"
                  className="block w-[136px] h-[48px] px-[28px] py-[12px] text-[16px] text-#111827 rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                  onClick={closeCouponSettingModal}
                >
                  キャンセル
                </button>
                <button
                  type="button"
                  className={`block  w-[90px] h-[48px] py-[12px] px-[32px] text-[16px] font-medium font-hiragino border-none rounded-[12px]  ${isOkButtonDisabled() ? "bg-[#E4E4E7] text-disableText" : "bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer focus:ring-blue-300"}`}
                  onClick={handleDistributionSubmit}
                  disabled={isOkButtonDisabled()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isAfterCreateCouponMOdalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={() => {
            setSelectedCouponId("");
            setIsAfterCreateCouponMOdalOpen(false);
            navigate(location.pathname, { replace: true, state: {} });
          }}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[350px] h-[372px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[302px] h-[324px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <div className="flex flex-col gap-[20px]">
                <div>
                  <div className="text-center">
                    <span className="material-symbols-rounded text-[104px] text-#047857">
                      check_circle
                    </span>
                  </div>
                  <div className="text-16px text-center text-#111827 font-hiragino leading-8">
                    クーポンを登録しました
                  </div>
                </div>
                <div className="flex flex-col gap-12px">
                  <div className="text-14px text-#111827 font-hiragino leading-5">
                    まだ登録されたクーポンは配布されていません。配布設定に移りますか？
                  </div>
                  <button
                    type="button"
                    className="block w-[302px] h-[48px] py-[12px] px-[24px] text-[16px] font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer focus:ring-blue-300" // 通常時のスタイル
                    onClick={() => {
                      setIsAfterCreateCouponMOdalOpen(false);
                      openCouponSettingModal(selectedCouponId);
                      navigate(location.pathname, { replace: true, state: {} });
                    }}
                  >
                    配布設定に移る
                  </button>
                  <button
                    type="button"
                    className="block w-[302px] h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 bg-#F8FAFC rounded-[12px] font-semibold font-hiragino border-none focus:outline-none border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                    onClick={() => {
                      setSelectedCouponId("");
                      setIsAfterCreateCouponMOdalOpen(false);
                      navigate(location.pathname, { replace: true, state: {} });
                    }}
                  >
                    閉じる
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isDeleteModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={() => {
            setSelectedCouponId("");
            setIsDeleteModalOpen(false);
          }}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[432px] h-[92px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[432px] h-[92px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <div className="flex flex-col gap-[20px]">
                <div className="text-18px text-#111827 text-medium font-hiragino">
                  このクーポンを削除しますか？
                </div>
                <div className="flex justify-center gap-12px">
                  <button
                    type="button"
                    className="block w-[210px] h-[48px] py-[12px] px-[24px] text-[16px] bg-#F8FAFC rounded-[12px] font-semibold font-hiragino border-none focus:outline-none border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                    onClick={() => {
                      setSelectedCouponId("");
                      setIsDeleteModalOpen(false);
                    }}
                  >
                    キャンセル
                  </button>
                  <button
                    type="button"
                    className="block w-[210px] h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer focus:ring-blue-300" // 通常時のスタイル
                    onClick={handleDeleteSubmit}
                  >
                    はい
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CouponListPage;
