import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "virtual:uno.css";
import "normalize.css";
import * as Sentry from "@sentry/react";

import { Client } from "./Client";
import CouponListPage from "./components/pages/couponListPage";
import DistributedCouponListPage from "./components/pages/distributedCouponListPage";
import CreateCouponPage from "./components/pages/createTemplateCouponPage";
import LoginPage from "./components/pages/loginPage";
import ConsoleLayout from "./components/templates/consoleLayout";
import ProtectedLayout from "./components/templates/protectedLayout";
import UpdateTemplateCouponPage from "./components/pages/updateTemplateCouponPage";
import ShopSettingPage from "./components/pages/shopSettingPage";
import ShopEditPage from "./components/pages/shopEditPage";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <ConsoleLayout />,
        children: [
          {
            path: "/",
            element: <CouponListPage />,
          },
          {
            path: "distributed_coupons",
            element: <DistributedCouponListPage />,
          },
          {
            path: "create_template_coupon",
            element: <CreateCouponPage />,
          },
          {
            path: "update_template_coupon/:id",
            element: <UpdateTemplateCouponPage />,
          },
          {
            path: "shop_setting",
            element: <ShopSettingPage />,
          },
          {
            path: "shop_edit",
            element: <ShopEditPage />,
          },
        ],
      },
      {
        path: "test",
        element: <ConsoleLayout />,
      },
    ],
  },
]);

Sentry.init({
  dsn: "https://886cdba5280b93e448b7c6bf151f0d14@o4504292631707648.ingest.us.sentry.io/4508415922143232",
  integrations: [],
  environment: import.meta.env.VITE_APP_ENV,
  enabled: import.meta.env.VITE_APP_ENV !== "local",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Client>
      <RouterProvider router={router} />
    </Client>
  </React.StrictMode>
);
