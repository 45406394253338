import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
  useMutationUpdateTemplateCoupon,
  useQueryGetShopByID,
  useQueryGetTemplateCouponByID,
} from "../../hooks/useQuery";

const UpdateTemplateCouponPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const navigate = useNavigate();
  const [couponName, setCouponName] = useState("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageData, setImageData] = useState<Uint8Array | undefined>(undefined);
  const [foodWeight, setFoodWeight] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountType, setDiscountType] = useState<
    "percentage_discount" | "fixed_amount_discount"
  >("percentage_discount");
  const [discountValue, setDiscountValue] = useState("");
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams<{ id: string }>();
  const { data: getTemplateCouponByID } = useQueryGetTemplateCouponByID(
    id || ""
  );
  const { data: getShopByID } = useQueryGetShopByID();
  const updateTemplateCoupon = useMutationUpdateTemplateCoupon(
    getTemplateCouponByID?.coupon?.id || ""
  );
  const [gender, setGender] = useState<"no_setting" | "male" | "female">(
    "no_setting"
  ); // 性別
  const [isSetAge, setIsSetAge] = useState(false); // 年齢設定の有無
  const [minAge, setMinAge] = useState(""); // 下限年齢
  const [maxAge, setMaxAge] = useState(""); // 上限年齢
  const [errors, setErrors] = useState({
    foodWeight: "",
    originalPrice: "",
    discountValue: "",
    minAge: "",
    maxAge: "",
    ageRange: "",
  });

  const toHalfWidth = (str: string) =>
    str.replace(/[！-～]/g, (char) =>
      String.fromCharCode(char.charCodeAt(0) - 0xfee0)
    );

  const handleMinAgeIncrement = () => {
    setMinAge((prev) => String(Number(toHalfWidth(prev)) + 1));
  };

  const handleMinAgeDecrement = () => {
    setMinAge((prev) => String(Math.max(Number(toHalfWidth(prev)) - 1, 0)));
  };

  const handleMaxAgeIncrement = () => {
    setMaxAge((prev) => String(Number(toHalfWidth(prev)) + 1));
  };

  const handleMaxAgeDecrement = () => {
    setMaxAge((prev) => String(Math.max(Number(toHalfWidth(prev)) - 1, 0)));
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result instanceof ArrayBuffer) {
          const arrayBuffer = reader.result;
          const uint8Array = new Uint8Array(arrayBuffer); // ArrayBufferをUint8Arrayに変換
          setImageData(uint8Array); // Uint8Arrayをstateにセット

          const base64String = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setImagePreview(`data:${file.type};base64,${base64String}`); // プレビュー用にBase64変換
        }
      };
      reader.readAsArrayBuffer(file); // ArrayBufferとして読み込む
    }
  };

  const isFormValid = (): boolean => {
    return (
      couponName.trim() !== "" && // クーポン名が入力されているか
      discountValue.trim() !== "" && // 割引値または割引率が入力されているか
      reason.trim() !== "" && // 割引理由が入力されているか
      imageData !== undefined && // 画像が選択されているか
      (!isSetAge || minAge.trim() !== "") && // 年齢が設定されている場合は入力があるか
      (!isSetAge || maxAge.trim() !== "") // 年齢が設定されている場合は入力があるか
    );
  };

  const calculateDiscountedPrice = (): string | null => {
    const originalPriceNumber = parseFloat(originalPrice);
    const discountValueNumber = parseFloat(discountValue);

    if (isNaN(originalPriceNumber) || isNaN(discountValueNumber)) {
      return null;
    }

    if (discountType === "percentage_discount") {
      const discountedPrice =
        originalPriceNumber * (1 - discountValueNumber / 100);
      return discountedPrice.toFixed(0); // 小数点以下を切り捨てて整数値に
    } else if (discountType === "fixed_amount_discount") {
      const discountedPrice = originalPriceNumber - discountValueNumber;
      return discountedPrice.toFixed(0); // 小数点以下を切り捨てて整数値に
    }

    return null;
  };

  // 半角数字のみ許可する関数
  const isHalfWidthNumber = (value: string): boolean => {
    return /^[0-9]+$/.test(value);
  };

  const isNumericInput = (str: string) => /^[0-9０-９]*$/.test(str);

  // バリデーション関数
  const validateForm = () => {
    let hasError = false;
    const newErrors = {
      foodWeight: "",
      originalPrice: "",
      discountValue: "",
      minAge: "",
      maxAge: "",
      ageRange: "",
    };

    // originalPriceが入力されている場合のみバリデーション
    if (
      originalPrice !== "" &&
      (!isHalfWidthNumber(originalPrice) || isNaN(Number(originalPrice)))
    ) {
      newErrors.originalPrice = "定価は半角数字で入力してください";
      hasError = true;
    }

    // ディスカウント値のバリデーション
    if (
      !isHalfWidthNumber(discountValue) ||
      discountValue === "" ||
      isNaN(Number(discountValue))
    ) {
      newErrors.discountValue = "ディスカウント値は半角数字で入力してください";
      hasError = true;
    } else if (
      originalPrice !== "" &&
      discountType === "fixed_amount_discount" &&
      Number(discountValue) >= Number(originalPrice)
    ) {
      newErrors.discountValue = "割引額が定価を超えています";
      hasError = true;
    } else if (
      originalPrice !== "" &&
      discountType === "percentage_discount" &&
      Number(discountValue) > 100
    ) {
      newErrors.discountValue = "割引率は100%以下にしてください";
      hasError = true;
    }

    // 年齢のバリデーション
    if (
      isSetAge &&
      (isNaN(Number(minAge)) || !isHalfWidthNumber(minAge) || minAge === "")
    ) {
      newErrors.minAge = "下限年齢は半角数字で入力してください";
      hasError = true;
    }

    if (
      isSetAge &&
      (isNaN(Number(maxAge)) || !isHalfWidthNumber(maxAge) || maxAge === "")
    ) {
      newErrors.maxAge = "上限年齢は半角数字で入力してください";
      hasError = true;
    }

    // 下限年齢 < 上限年齢のチェック
    if (
      isSetAge &&
      minAge !== "" &&
      maxAge !== "" &&
      Number(minAge) >= Number(maxAge)
    ) {
      newErrors.ageRange = "上限年齢は下限年齢より大きい値を入力してください";
      hasError = true;
    }

    setErrors(newErrors);
    return !hasError;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    await updateTemplateCoupon.mutateAsync({
      coupon: {
        id: id,
        couponName: couponName,
        imageData: imageData,
        isSetOriginalPrice: originalPrice.trim() !== "",
        originalPrice:
          originalPrice.trim() !== "" ? BigInt(originalPrice) : undefined,
        discountMethod: discountType,
        discountValue: BigInt(discountValue),
        reason: reason,
        description: description,
        isSetGender: gender != "no_setting",
        gender: gender,
        isSetAge: isSetAge,
        minimumAge: isSetAge ? BigInt(minAge) : undefined,
        maximumAge: isSetAge ? BigInt(maxAge) : undefined,
        foodWeight: BigInt(foodWeight),
      },
    });
    navigate("/");
  };

  // バツボタンで画像を削除
  const removeImage = () => {
    setImagePreview(null);
    setImageData(undefined);
  };

  useEffect(() => {
    if (
      getTemplateCouponByID &&
      !couponName &&
      !foodWeight &&
      !originalPrice &&
      !discountValue &&
      !reason &&
      !description &&
      !imagePreview &&
      !imageData &&
      gender === "no_setting" &&
      !isSetAge &&
      !minAge &&
      !maxAge
    ) {
      setCouponName(getTemplateCouponByID.coupon?.couponName || "");
      const base64Image = getTemplateCouponByID.coupon?.imageBase64 || "";
      if (base64Image.startsWith("data:image/")) {
        setImagePreview(base64Image);
      } else {
        setImagePreview(`data:image/png;base64,${base64Image}`);
      }
      const base64Data = base64Image.split(",")[1];
      const binaryString = window.atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      setImageData(bytes);

      setFoodWeight(
        getTemplateCouponByID.coupon?.foodWeight
          ? String(getTemplateCouponByID.coupon.foodWeight)
          : ""
      );
      setOriginalPrice(
        getTemplateCouponByID.coupon?.isSetOriginalPrice
          ? String(getTemplateCouponByID.coupon.originalPrice)
          : ""
      );
      setDiscountType(
        getTemplateCouponByID.coupon?.discountMethod == "percentage_discount"
          ? "percentage_discount"
          : "fixed_amount_discount"
      );
      setDiscountValue(String(getTemplateCouponByID.coupon?.discountValue));
      setReason(getTemplateCouponByID.coupon?.reason || "");
      setDescription(getTemplateCouponByID.coupon?.description || "");
      setGender(
        getTemplateCouponByID.coupon?.isSetGender
          ? (getTemplateCouponByID.coupon?.gender as "male" | "female")
          : "no_setting"
      );
      if (getTemplateCouponByID.coupon?.isSetAge) {
        setIsSetAge(true);
        setMinAge(String(getTemplateCouponByID.coupon?.minimumAge));
        setMaxAge(String(getTemplateCouponByID.coupon?.maximumAge));
      }
    }
  }, [getTemplateCouponByID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sharedProps: SharedProps = {
    navigate,
    couponName,
    setCouponName,
    imagePreview,
    foodWeight,
    setFoodWeight,
    originalPrice,
    setOriginalPrice,
    discountType,
    setDiscountType,
    discountValue,
    setDiscountValue,
    reason,
    setReason,
    description,
    setDescription,
    gender,
    setGender,
    isSetAge,
    setIsSetAge,
    minAge,
    setMinAge,
    maxAge,
    setMaxAge,
    errors,
    toHalfWidth,
    handleMinAgeIncrement,
    handleMinAgeDecrement,
    handleMaxAgeIncrement,
    handleMaxAgeDecrement,
    handleImageUpload,
    isFormValid,
    calculateDiscountedPrice,
    isNumericInput,
    removeImage,
    handleSubmit,
    getShopByID,
  };

  return isMobile ? (
    <MobileLoginPage {...sharedProps} />
  ) : (
    <DesktopLoginPage {...sharedProps} />
  );
};

interface SharedProps {
  navigate: NavigateFunction;
  couponName: string;
  setCouponName: (value: string) => void;
  imagePreview: string | null;
  foodWeight: string;
  setFoodWeight: React.Dispatch<React.SetStateAction<string>>;
  originalPrice: string;
  setOriginalPrice: (value: string) => void;
  discountType: "percentage_discount" | "fixed_amount_discount";
  setDiscountType: (
    value: "percentage_discount" | "fixed_amount_discount"
  ) => void;
  discountValue: string;
  setDiscountValue: (value: string) => void;
  reason: string;
  setReason: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  gender: "no_setting" | "male" | "female";
  setGender: (value: "no_setting" | "male" | "female") => void;
  isSetAge: boolean;
  setIsSetAge: (value: boolean) => void;
  minAge: string;
  setMinAge: (value: string) => void;
  maxAge: string;
  setMaxAge: (value: string) => void;
  errors: {
    foodWeight: string;
    originalPrice: string;
    discountValue: string;
    minAge: string;
    maxAge: string;
    ageRange: string;
  };
  toHalfWidth: (str: string) => string;
  handleMinAgeIncrement: () => void;
  handleMinAgeDecrement: () => void;
  handleMaxAgeIncrement: () => void;
  handleMaxAgeDecrement: () => void;
  handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFormValid: () => boolean;
  calculateDiscountedPrice: () => string | null;
  isNumericInput: (str: string) => boolean;
  removeImage: () => void;
  handleSubmit: () => Promise<void>;
  getShopByID:
    | {
        shop?: {
          name?: string;
          address?: string;
        };
      }
    | undefined;
}

const MobileLoginPage: React.FC<SharedProps> = ({
  navigate,
  couponName,
  setCouponName,
  imagePreview,
  foodWeight,
  setFoodWeight,
  originalPrice,
  setOriginalPrice,
  discountType,
  setDiscountType,
  discountValue,
  setDiscountValue,
  reason,
  setReason,
  description,
  setDescription,
  gender,
  setGender,
  isSetAge,
  setIsSetAge,
  minAge,
  setMinAge,
  maxAge,
  setMaxAge,
  errors,
  toHalfWidth,
  handleMinAgeIncrement,
  handleMinAgeDecrement,
  handleMaxAgeIncrement,
  handleMaxAgeDecrement,
  handleImageUpload,
  isFormValid,
  calculateDiscountedPrice,
  isNumericInput,
  removeImage,
  handleSubmit,
  getShopByID,
}) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  return (
    <>
      <div className="p-16px">
        <div className="flex flex-col gap-[16px] grow">
          <div className="flex">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                arrow_back
              </span>
            </div>
            <h2 className="m-0 text-[20px] font-semibold font-hiragino">
              編集
            </h2>
          </div>
          <div className="">
            <form className="flex flex-col gap-[16px]  p-[24px] rounded-[16px] bg-white">
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    商品名
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <input
                  className="w-full box-border py-[16px] px-[18px] rounded-[12px] border-solid border-[1px] border-lightGray text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText"
                  id="couponName"
                  type="text"
                  placeholder="クーポン適用対象の商品名を入力してください"
                  value={couponName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCouponName(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    商品画像
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                {imagePreview ? (
                  <div className="relative w-32 h-32 mb-4">
                    <img
                      src={imagePreview}
                      alt="Uploaded Preview"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <button
                      onClick={removeImage}
                      className="absolute top-0 right-0 text-white w-5 h-5 flex items-center justify-center"
                    >
                      <span className="material-symbols-rounded text-[20px] text-black hover:cursor-pointer">
                        close
                      </span>
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="dropzone-file"
                    className="w-[106px] h-[24px] pl-[20px] pr-[24px] py-[8px] border-solid border-[1px] border-lightGray rounded-[12px] cursor-pointer bg-#F8FAFC hover:bg-gray-100"
                  >
                    <div className="flex gap-[2px]">
                      <span className="material-symbols-rounded text-[24px]">
                        upload
                      </span>
                      <div className="text-[16px] text-#111827 font-hiragino font-medium leading-6">
                        画像を追加
                      </div>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/png, image/jpeg"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </label>
                )}
              </div>
              <div className="flex flex-col gap-[4px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    商品の重さ
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <div className="text-14px text-#374151CC leading-5">
                  今後分析機能においてどれくらいの量のフードロスが削減できたか計算するために用います。正確な値でなくても構わないのでなるべく入力してください。
                </div>
                <div className="flex gap-[16px]">
                  <div className="w-full">
                    <input
                      className={`w-full flex-grow box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                        errors.foodWeight
                          ? "border-red-500"
                          : "border-lightGray"
                      } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                      id="foodWeight"
                      type="text"
                      placeholder="商品の重さを入力してください"
                      value={foodWeight}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const input = event.target.value;
                        if (isNumericInput(input)) {
                          setFoodWeight(toHalfWidth(input));
                        }
                      }}
                    />
                    {errors.foodWeight && (
                      <p className="text-red-500 text-sm">
                        {errors.foodWeight}
                      </p>
                    )}
                  </div>
                  <div className="text-[16px] text-#111827 font-hiragino pt-[35px]">
                    g
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    定価
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <div className="flex gap-[16px]">
                  <div className="w-full">
                    <input
                      className={`w-full box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                        errors.originalPrice
                          ? "border-red-500"
                          : "border-lightGray"
                      } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                      id="originPrice"
                      type="text"
                      placeholder="定価を指定する場合は入力してください"
                      value={originalPrice}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const input = event.target.value;
                        if (isNumericInput(input)) {
                          setOriginalPrice(toHalfWidth(input));
                        }
                      }}
                    />
                    {errors.originalPrice && (
                      <p className="text-red-500 text-sm">
                        {errors.originalPrice}
                      </p>
                    )}
                  </div>

                  <div className="text-[16px] text-#111827 font-hiragino pt-[35px]">
                    円
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    値下げ方法
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <div className="flex gap-[8px]">
                  <input
                    checked={discountType === "percentage_discount"}
                    id="percentageDiscount"
                    type="radio"
                    value="percentage_discount"
                    onChange={() => {
                      setDiscountType("percentage_discount");
                      setDiscountValue("");
                    }}
                    name="default-radio"
                    className="w-[20px] h-[20px]"
                  />
                  <label
                    htmlFor="percentageDiscount"
                    className="text-[16px] text-#111827 font-hiragino"
                  >
                    割引き（例：10%引き）
                  </label>
                </div>
                <div className="flex gap-[8px]">
                  <input
                    id="fixedAmountDiscount"
                    type="radio"
                    checked={discountType === "fixed_amount_discount"}
                    value="fixed_amount_discount"
                    onChange={() => {
                      setDiscountType("fixed_amount_discount");
                      setDiscountValue("");
                    }}
                    name="default-radio"
                    className="w-[20px] h-[20px]"
                  />
                  <label
                    htmlFor="fixedAmountDiscount"
                    className="text-[16px] text-#111827 font-hiragino"
                  >
                    値引き（例：100円引き）
                  </label>
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                {discountType === "percentage_discount" && (
                  <>
                    <label
                      className="block flex gap-[8px]"
                      htmlFor="couponName"
                    >
                      <div className="text-[16px] text-#111827 font-hiragino">
                        割引率
                      </div>
                      <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                        必須
                      </div>
                    </label>
                    <div className="flex gap-[16px]">
                      <div className="w-full">
                        <input
                          className={`w-full box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                            errors.discountValue
                              ? "border-red-500"
                              : "border-lightGray"
                          } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                          type="text"
                          placeholder="割引率を入力してください"
                          value={discountValue}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const input = event.target.value;
                            if (isNumericInput(input)) {
                              setDiscountValue(toHalfWidth(input));
                            }
                          }}
                        />
                        {errors.discountValue && (
                          <p className="text-red-500 text-sm">
                            {errors.discountValue}
                          </p>
                        )}
                      </div>
                      <div className="min-w-[48px] border-box text-[16px] text-#111827 font-hiragino pt-[25px]">
                        ％引き
                      </div>
                    </div>
                  </>
                )}
                {discountType === "fixed_amount_discount" && (
                  <>
                    <label
                      className="block flex gap-[8px]"
                      htmlFor="couponName"
                    >
                      <div className="text-[16px] text-#111827 font-hiragino">
                        割引額
                      </div>
                      <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                        必須
                      </div>
                    </label>
                    <div className="flex gap-[16px]">
                      <div className="w-full">
                        <input
                          className={`w-full box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                            errors.discountValue
                              ? "border-red-500"
                              : "border-lightGray"
                          } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                          id="originPrice"
                          type="text"
                          placeholder="割引額を入力してください"
                          value={discountValue}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const input = event.target.value;
                            if (isNumericInput(input)) {
                              setDiscountValue(toHalfWidth(input));
                            }
                          }}
                        />
                        {errors.discountValue && (
                          <p className="text-red-500 text-sm">
                            {errors.discountValue}
                          </p>
                        )}
                      </div>
                      <div className="min-w-[48px] border-box text-[16px] text-#111827 font-hiragino pt-[25px]">
                        円引き
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    クーポン配布理由
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <textarea
                  className="w-full box-border h-[200px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="couponName"
                  placeholder="クーポン配布理由"
                  value={reason}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setReason(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    商品説明
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <textarea
                  className="w-full box-border h-[200px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="description"
                  placeholder="商品説明"
                  value={description}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setDescription(event.target.value)
                  }
                />
              </div>
            </form>
          </div>
          <div className="flex flex-col rounded-[16px] bg-white">
            <div className="flex flex-col gap-8px h-[24px] px-[24px] py-[20px] border-b-solid border-b-[1px] border-b-lightGray ">
              <div className="text-[18px] font-semibold text-#111827 font-hiragino leading-6">
                パーソナライズ設定
              </div>
            </div>
            <div className="flex flex-col gap-[16px] p-[24px]">
              <form className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    性別
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <div className="flex gap-[8px]">
                  <div className="flex gap-[4px]">
                    <input
                      defaultChecked
                      id="inline-radio"
                      type="radio"
                      value="no_setting"
                      name="gender"
                      className="w-[20px] h-[20px]"
                      checked={gender === "no_setting"}
                      onChange={() => setGender("no_setting")}
                    />
                    <label
                      htmlFor="inline-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      設定しない
                    </label>
                  </div>
                  <div className="flex gap-[4px]">
                    <input
                      id="inline-2-radio"
                      type="radio"
                      value="male"
                      name="gender"
                      className="w-[20px] h-[20px]"
                      checked={gender === "male"}
                      onChange={() => setGender("male")}
                    />
                    <label
                      htmlFor="inline-2-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      男性
                    </label>
                  </div>
                  <div className="flex gap-[4px]">
                    <input
                      id="inline-checked-radio"
                      type="radio"
                      value="female"
                      name="gender"
                      className="w-[20px] h-[20px]"
                      checked={gender === "female"}
                      onChange={() => setGender("female")}
                    />
                    <label
                      htmlFor="inline-checked-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      女性
                    </label>
                  </div>
                </div>
              </form>
              <form className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    年齢
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <div className="flex gap-[8px]">
                  <div className="flex gap-[4px]">
                    <input
                      defaultChecked
                      id="inline-radio"
                      type="radio"
                      value="no_setting"
                      name="ageSetting"
                      className="w-[20px] h-[20px]"
                      checked={!isSetAge}
                      onChange={() => {
                        setIsSetAge(false);
                        setMinAge("");
                        setMaxAge("");
                      }}
                    />
                    <label
                      htmlFor="inline-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      設定しない
                    </label>
                  </div>
                  <div className="flex gap-[4px]">
                    <input
                      id="inline-2-radio"
                      type="radio"
                      value="age_setting"
                      name="inline-radio-group"
                      className="w-[20px] h-[20px]"
                      checked={isSetAge}
                      onChange={() => setIsSetAge(true)}
                    />
                    <label
                      htmlFor="inline-2-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      設定する
                    </label>
                  </div>
                </div>
                {/* 年齢入力フォームの表示を制御 */}
                {isSetAge && (
                  <div className="flex gap-[8px]">
                    <div className="relative">
                      <input
                        className={`w-[175px] box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                          errors.minAge || errors.ageRange
                            ? "border-red-500"
                            : "border-lightGray"
                        } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                        id="min-age"
                        type="text"
                        placeholder="下限年齢"
                        value={minAge}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (isNumericInput(input)) {
                            setMinAge(toHalfWidth(input));
                          }
                        }}
                      />
                      <div className="absolute right-0 top-0 bottom-0 flex flex-col justify-center items-center pr-2">
                        {Number(minAge) > 1 && (
                          <button
                            type="button"
                            onClick={handleMinAgeDecrement}
                            className="w-24px h-24px bg-white text-gray-500 border-none"
                          >
                            <span className="material-symbols-rounded text-24px">
                              expand_less
                            </span>
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={handleMinAgeIncrement}
                          className="w-24px h-24px bg-white text-gray-500 border-none"
                        >
                          <span className="material-symbols-rounded text-24px ">
                            expand_more
                          </span>
                        </button>
                      </div>
                      {errors.minAge && (
                        <p className="text-red-500 text-sm">{errors.minAge}</p>
                      )}
                    </div>
                    <div className="text-[16px] text-#111827 font-hiragino leading-12">
                      歳
                    </div>
                    <div className="text-[16px] text-#111827 font-hiragino leading-12">
                      〜
                    </div>
                    <div className="relative">
                      <input
                        className={`w-[175px] box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                          errors.maxAge || errors.ageRange
                            ? "border-red-500"
                            : "border-lightGray"
                        } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                        id="max-age"
                        type="text"
                        placeholder="上限年齢"
                        value={maxAge}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (isNumericInput(input)) {
                            setMaxAge(toHalfWidth(input));
                          }
                        }}
                      />
                      <div className="absolute right-0 top-0 bottom-0 flex flex-col justify-center items-center pr-2">
                        {Number(maxAge) > 1 && (
                          <button
                            type="button"
                            onClick={handleMaxAgeDecrement}
                            className="w-24px h-24px bg-white text-gray-500 border-none"
                          >
                            <span className="material-symbols-rounded text-24px">
                              expand_less
                            </span>
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={handleMaxAgeIncrement}
                          className="w-24px h-24px bg-white text-gray-500 border-none"
                        >
                          <span className="material-symbols-rounded text-24px ">
                            expand_more
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="text-[16px] text-#111827 font-hiragino leading-12">
                      歳
                    </div>
                  </div>
                )}
                {errors.minAge && (
                  <div className="text-red-500 text-sm pl-10px">
                    {errors.minAge}
                  </div>
                )}
                {errors.maxAge && (
                  <div className="text-red-500 text-sm pl-10px">
                    {errors.maxAge}
                  </div>
                )}
                {errors.ageRange && (
                  <div className="text-red-500 text-sm pl-10px">
                    {errors.ageRange}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="mt-16px  bg-#D1D5DB80">
          <div
            className={`flex justify-around m-0 py-24px h-24px ${isPreviewVisible && "border-b-solid border-b-1px border-b-disableText"}`}
          >
            <div className="w-24px"></div>
            <h2 className="m-0 text-center text-18px font-semibold text-#111827 font-hiragino leading-7">
              プレビュー
            </h2>
            <div
              style={{
                WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                outline: "none", // タップ時のアウトラインを無効化
              }}
              onClick={() => setIsPreviewVisible(!isPreviewVisible)}
              className=""
            >
              <span className="material-symbols-rounded text-[24px]">
                {isPreviewVisible ? "expand_less" : "expand_more"}
              </span>
            </div>
          </div>
          {isPreviewVisible && (
            <div className="flex flex-col gap-24px p-24px">
              <div className="mx-auto h-24px py-8px px-16px rounded-9999px bg-lightGray text-18px font-semibold text-secondary font-hiragino leading-6">
                クーポン詳細画面
              </div>
              <div className="flex flex-col gap-12px p-16px bg-#FFF rounded-12px">
                <div className="flex flex-col gap-8px break-all">
                  {getShopByID?.shop?.name ? (
                    <div className="text-16px text-#111827 font-hiragino">
                      {getShopByID?.shop?.name}
                    </div>
                  ) : (
                    <></>
                  )}
                  {getShopByID?.shop?.address ? (
                    <div className="text-14px text-#001EFB font-hiragino break-all">
                      {getShopByID?.shop?.address}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {couponName == "" ? (
                  <></>
                ) : (
                  <div className="text-20px text-#303846 font-hiragino  break-all">
                    {couponName}
                  </div>
                )}
                {discountValue == "" ? (
                  <></>
                ) : (
                  <div>
                    <div className="flex gap-8px">
                      <div className="text-28px font-semibold text-#B91C1C font-hiragino break-all">
                        -{discountValue}
                        {discountType == "percentage_discount" ? "%" : "円"}
                      </div>
                      {originalPrice != "" && (
                        <div className="flex gap-5px">
                          <div className="flex">
                            <div className="text-28px font-semibold text-#111827 font-hiragino break-all">
                              {calculateDiscountedPrice()}
                            </div>
                            <div className="text-20px font-semibold text-#111827 font-hiragino leading-9">
                              円
                            </div>
                          </div>
                          <div className="text-16px text-#111827 font-hiragino leading-10">
                            税込
                          </div>
                        </div>
                      )}
                    </div>
                    {originalPrice != "" && (
                      <div className="text-16px text-#111827 font-hiragino line-through break-all">
                        (定価{originalPrice}円)
                      </div>
                    )}
                  </div>
                )}
                {reason && (
                  <div className="flex flex-col gap-4px">
                    <div className="text-14px font-semibold text-#111827 font-hiragino">
                      クーポン配布理由
                    </div>
                    <div
                      className="break-all"
                      dangerouslySetInnerHTML={{
                        __html: reason.replace(/\n/g, "<br />"),
                      }}
                    />
                  </div>
                )}
                {imagePreview ? (
                  <div className="w-full aspect-[4/3] mx-auto rounded-8px overflow-hidden">
                    <img
                      src={imagePreview}
                      alt="Uploaded Preview"
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div className="mx-auto w-200px h-139px rounded-15px bg-lightGray text-center">
                    <span className="material-symbols-rounded pt-37px text-[60px] text-#4B556380 rounded-9999">
                      restaurant
                    </span>
                  </div>
                )}
                {description && (
                  <div
                    className="break-words"
                    dangerouslySetInnerHTML={{
                      __html: description.replace(/\n/g, "<br />"),
                    }}
                  />
                )}
                <div className="text-16px text-#111827 font-hiragino">
                  ※クーポンは商品の売れ行き次第で品切れになる場合がございます
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-16px flex flex-col gap-[12px] py-16px">
          <button
            type="button"
            onClick={handleSubmit}
            style={{
              WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
              outline: "none", // タップ時のアウトラインを無効化
            }}
            disabled={!isFormValid()}
            className={`block mx-auto w-[200px] h-[48px] py-[12px] pl-[28px] pr-[32px] rounded-[12px] ${
              isFormValid()
                ? "bg-#001CEC text-white hover:bg-#334BFC cursor-pointer"
                : "bg-#E4E4E7 text-disableText cursor-not-allowed"
            } border-none focus:outline-none`}
          >
            更新する
          </button>
          <button
            type="button"
            onClick={() => navigate(-1)}
            style={{
              WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
              outline: "none", // タップ時のアウトラインを無効化
            }}
            className="block mx-auto w-[200px] h-[48px] py-[12px] pl-[28px] pr-[32px] rounded-[12px] bg-#E5E7EB border-solid border-lightGray border-[1px] text-#111827 hover:bg-gray-100 cursor-pointer"
          >
            キャンセル
          </button>
        </div>
      </div>
    </>
  );
};

const DesktopLoginPage: React.FC<SharedProps> = ({
  navigate,
  couponName,
  setCouponName,
  imagePreview,
  foodWeight,
  setFoodWeight,
  originalPrice,
  setOriginalPrice,
  discountType,
  setDiscountType,
  discountValue,
  setDiscountValue,
  reason,
  setReason,
  description,
  setDescription,
  gender,
  setGender,
  isSetAge,
  setIsSetAge,
  minAge,
  setMinAge,
  maxAge,
  setMaxAge,
  errors,
  toHalfWidth,
  handleMinAgeIncrement,
  handleMinAgeDecrement,
  handleMaxAgeIncrement,
  handleMaxAgeDecrement,
  handleImageUpload,
  isFormValid,
  calculateDiscountedPrice,
  isNumericInput,
  removeImage,
  handleSubmit,
  getShopByID,
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex flex-col gap-[24px] grow p-[24px]">
          <div className="flex">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                arrow_back
              </span>
            </div>
            <h2 className="m-0 text-[20px] font-semibold font-hiragino">
              編集
            </h2>
          </div>
          <div className="">
            <form className="flex flex-col gap-[16px]  p-[24px] rounded-[16px] bg-white">
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    商品名
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <input
                  className="w-full box-border py-[16px] px-[18px] rounded-[12px] border-solid border-[1px] border-lightGray text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText"
                  id="couponName"
                  type="text"
                  placeholder="クーポン適用対象の商品名を入力してください"
                  value={couponName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCouponName(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    商品画像
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                {imagePreview ? (
                  <div className="relative w-32 h-32 mb-4">
                    <img
                      src={imagePreview}
                      alt="Uploaded Preview"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <button
                      onClick={removeImage}
                      className="absolute top-0 right-0 text-white w-5 h-5 flex items-center justify-center"
                    >
                      <span className="material-symbols-rounded text-[20px] text-black hover:cursor-pointer">
                        close
                      </span>
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="dropzone-file"
                    className="w-[106px] h-[24px] pl-[20px] pr-[24px] py-[8px] border-solid border-[1px] border-lightGray rounded-[12px] cursor-pointer bg-#F8FAFC hover:bg-gray-100"
                  >
                    <div className="flex gap-[2px]">
                      <span className="material-symbols-rounded text-[24px]">
                        upload
                      </span>
                      <div className="text-[16px] text-#111827 font-hiragino font-medium leading-6">
                        画像を追加
                      </div>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/png, image/jpeg"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </label>
                )}
              </div>
              <div className="flex flex-col gap-[4px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    商品の重さ
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <div className="text-14px text-#374151CC leading-5">
                  今後分析機能においてどれくらいの量のフードロスが削減できたか計算するために用います。正確な値でなくても構わないのでなるべく入力してください。
                </div>
                <div className="flex gap-[16px]">
                  <div className="w-full">
                    <input
                      className={`w-full flex-grow box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                        errors.foodWeight
                          ? "border-red-500"
                          : "border-lightGray"
                      } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                      id="foodWeight"
                      type="text"
                      placeholder="商品の重さを入力してください"
                      value={foodWeight}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const input = event.target.value;
                        if (isNumericInput(input)) {
                          setFoodWeight(toHalfWidth(input));
                        }
                      }}
                    />
                    {errors.foodWeight && (
                      <p className="text-red-500 text-sm">
                        {errors.foodWeight}
                      </p>
                    )}
                  </div>
                  <div className="text-[16px] text-#111827 font-hiragino pt-[35px]">
                    g
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    定価
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <div className="flex gap-[16px]">
                  <div className="w-full">
                    <input
                      className={`w-full box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                        errors.originalPrice
                          ? "border-red-500"
                          : "border-lightGray"
                      } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                      id="originPrice"
                      type="text"
                      placeholder="定価を指定する場合は入力してください"
                      value={originalPrice}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const input = event.target.value;
                        if (isNumericInput(input)) {
                          setOriginalPrice(toHalfWidth(input));
                        }
                      }}
                    />
                    {errors.originalPrice && (
                      <p className="text-red-500 text-sm">
                        {errors.originalPrice}
                      </p>
                    )}
                  </div>

                  <div className="text-[16px] text-#111827 font-hiragino pt-[35px]">
                    円
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    値下げ方法
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <div className="flex gap-[8px]">
                  <input
                    checked={discountType === "percentage_discount"}
                    id="percentageDiscount"
                    type="radio"
                    value="percentage_discount"
                    onChange={() => {
                      setDiscountType("percentage_discount");
                      setDiscountValue("");
                    }}
                    name="default-radio"
                    className="w-[20px] h-[20px]"
                  />
                  <label
                    htmlFor="percentageDiscount"
                    className="text-[16px] text-#111827 font-hiragino"
                  >
                    割引き（例：10%引き）
                  </label>
                </div>
                <div className="flex gap-[8px]">
                  <input
                    id="fixedAmountDiscount"
                    type="radio"
                    checked={discountType === "fixed_amount_discount"}
                    value="fixed_amount_discount"
                    onChange={() => {
                      setDiscountType("fixed_amount_discount");
                      setDiscountValue("");
                    }}
                    name="default-radio"
                    className="w-[20px] h-[20px]"
                  />
                  <label
                    htmlFor="fixedAmountDiscount"
                    className="text-[16px] text-#111827 font-hiragino"
                  >
                    値引き（例：100円引き）
                  </label>
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                {discountType === "percentage_discount" && (
                  <>
                    <label
                      className="block flex gap-[8px]"
                      htmlFor="couponName"
                    >
                      <div className="text-[16px] text-#111827 font-hiragino">
                        割引率
                      </div>
                      <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                        必須
                      </div>
                    </label>
                    <div className="flex gap-[16px]">
                      <div className="w-full">
                        <input
                          className={`w-full box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                            errors.discountValue
                              ? "border-red-500"
                              : "border-lightGray"
                          } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                          type="text"
                          placeholder="割引率を入力してください"
                          value={discountValue}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const input = event.target.value;
                            if (isNumericInput(input)) {
                              setDiscountValue(toHalfWidth(input));
                            }
                          }}
                        />
                        {errors.discountValue && (
                          <p className="text-red-500 text-sm">
                            {errors.discountValue}
                          </p>
                        )}
                      </div>
                      <div className="w-[60px] border-box text-[16px] text-#111827 font-hiragino pt-[25px]">
                        ％引き
                      </div>
                    </div>
                  </>
                )}
                {discountType === "fixed_amount_discount" && (
                  <>
                    <label
                      className="block flex gap-[8px]"
                      htmlFor="couponName"
                    >
                      <div className="text-[16px] text-#111827 font-hiragino">
                        割引額
                      </div>
                      <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                        必須
                      </div>
                    </label>
                    <div className="flex gap-[16px]">
                      <div className="w-full">
                        <input
                          className={`w-full box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                            errors.discountValue
                              ? "border-red-500"
                              : "border-lightGray"
                          } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                          id="originPrice"
                          type="text"
                          placeholder="割引額を入力してください"
                          value={discountValue}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const input = event.target.value;
                            if (isNumericInput(input)) {
                              setDiscountValue(toHalfWidth(input));
                            }
                          }}
                        />
                        {errors.discountValue && (
                          <p className="text-red-500 text-sm">
                            {errors.discountValue}
                          </p>
                        )}
                      </div>
                      <div className="w-[60px] border-box text-[16px] text-#111827 font-hiragino pt-[25px]">
                        円引き
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    クーポン配布理由
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <textarea
                  className="w-full box-border h-[200px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="couponName"
                  placeholder="クーポン配布理由"
                  value={reason}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setReason(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    商品説明
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <textarea
                  className="w-full box-border h-[200px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="description"
                  placeholder="商品説明"
                  value={description}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setDescription(event.target.value)
                  }
                />
              </div>
            </form>
          </div>
          <div className="flex flex-col rounded-[16px] bg-white">
            <div className="flex flex-col gap-8px h-[72px] px-[24px] py-[20px] border-b-solid border-b-[1px] border-b-lightGray ">
              <div className="text-[18px] font-semibold text-#111827 font-hiragino leading-6">
                パーソナライズ設定
              </div>
              <div className="text14px text-secondary font-hiragino leading-5">
                ここで設定することで、対象となるユーザーのミニアプリ上における「あなたにおすすめ」の上位に表示されやすくなります。
              </div>
            </div>
            <div className="flex flex-col gap-[16px] p-[24px]">
              <form className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    性別
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <div className="flex gap-[8px]">
                  <div className="flex gap-[4px]">
                    <input
                      defaultChecked
                      id="inline-radio"
                      type="radio"
                      value="no_setting"
                      name="gender"
                      className="w-[20px] h-[20px]"
                      checked={gender === "no_setting"}
                      onChange={() => setGender("no_setting")}
                    />
                    <label
                      htmlFor="inline-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      設定しない
                    </label>
                  </div>
                  <div className="flex gap-[4px]">
                    <input
                      id="inline-2-radio"
                      type="radio"
                      value="male"
                      name="gender"
                      className="w-[20px] h-[20px]"
                      checked={gender === "male"}
                      onChange={() => setGender("male")}
                    />
                    <label
                      htmlFor="inline-2-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      男性
                    </label>
                  </div>
                  <div className="flex gap-[4px]">
                    <input
                      id="inline-checked-radio"
                      type="radio"
                      value="female"
                      name="gender"
                      className="w-[20px] h-[20px]"
                      checked={gender === "female"}
                      onChange={() => setGender("female")}
                    />
                    <label
                      htmlFor="inline-checked-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      女性
                    </label>
                  </div>
                </div>
              </form>
              <form className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    年齢
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <div className="flex gap-[8px]">
                  <div className="flex gap-[4px]">
                    <input
                      defaultChecked
                      id="inline-radio"
                      type="radio"
                      value="no_setting"
                      name="ageSetting"
                      className="w-[20px] h-[20px]"
                      checked={!isSetAge}
                      onChange={() => {
                        setIsSetAge(false);
                        setMinAge("");
                        setMaxAge("");
                      }}
                    />
                    <label
                      htmlFor="inline-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      設定しない
                    </label>
                  </div>
                  <div className="flex gap-[4px]">
                    <input
                      id="inline-2-radio"
                      type="radio"
                      value="age_setting"
                      name="inline-radio-group"
                      className="w-[20px] h-[20px]"
                      checked={isSetAge}
                      onChange={() => setIsSetAge(true)}
                    />
                    <label
                      htmlFor="inline-2-radio"
                      className="text-[16px text-#111827 font-hiragino"
                    >
                      設定する
                    </label>
                  </div>
                </div>
                {/* 年齢入力フォームの表示を制御 */}
                {isSetAge && (
                  <div className="flex gap-[8px]">
                    <div className="relative">
                      <input
                        className={`w-[175px] box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                          errors.minAge || errors.ageRange
                            ? "border-red-500"
                            : "border-lightGray"
                        } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                        id="min-age"
                        type="text"
                        placeholder="下限年齢"
                        value={minAge}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (isNumericInput(input)) {
                            setMinAge(toHalfWidth(input));
                          }
                        }}
                      />
                      <div className="absolute right-0 top-0 bottom-0 flex flex-col justify-center items-center pr-2">
                        {Number(minAge) > 1 && (
                          <button
                            type="button"
                            onClick={handleMinAgeDecrement}
                            className="w-24px h-24px bg-white text-gray-500 border-none"
                          >
                            <span className="material-symbols-rounded text-24px">
                              expand_less
                            </span>
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={handleMinAgeIncrement}
                          className="w-24px h-24px bg-white text-gray-500 border-none"
                        >
                          <span className="material-symbols-rounded text-24px ">
                            expand_more
                          </span>
                        </button>
                      </div>
                      {errors.minAge && (
                        <p className="text-red-500 text-sm">{errors.minAge}</p>
                      )}
                    </div>
                    <div className="text-[16px] text-#111827 font-hiragino leading-12">
                      歳
                    </div>
                    <div className="text-[16px] text-#111827 font-hiragino leading-12">
                      〜
                    </div>
                    <div className="relative">
                      <input
                        className={`w-[175px] box-border py-[16px] px-[18px] rounded-[12px] border-[1px] border-solid ${
                          errors.maxAge || errors.ageRange
                            ? "border-red-500"
                            : "border-lightGray"
                        } text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText`}
                        id="max-age"
                        type="text"
                        placeholder="上限年齢"
                        value={maxAge}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (isNumericInput(input)) {
                            setMaxAge(toHalfWidth(input));
                          }
                        }}
                      />
                      <div className="absolute right-0 top-0 bottom-0 flex flex-col justify-center items-center pr-2">
                        {Number(maxAge) > 1 && (
                          <button
                            type="button"
                            onClick={handleMaxAgeDecrement}
                            className="w-24px h-24px bg-white text-gray-500 border-none"
                          >
                            <span className="material-symbols-rounded text-24px">
                              expand_less
                            </span>
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={handleMaxAgeIncrement}
                          className="w-24px h-24px bg-white text-gray-500 border-none"
                        >
                          <span className="material-symbols-rounded text-24px ">
                            expand_more
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="text-[16px] text-#111827 font-hiragino leading-12">
                      歳
                    </div>
                  </div>
                )}
                {errors.minAge && (
                  <div className="text-red-500 text-sm pl-10px">
                    {errors.minAge}
                  </div>
                )}
                {errors.maxAge && (
                  <div className="text-red-500 text-sm pl-10px">
                    {errors.maxAge}
                  </div>
                )}
                {errors.ageRange && (
                  <div className="text-red-500 text-sm pl-10px">
                    {errors.ageRange}
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="flex gap-[12px] mx-auto py-16px">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="block w-[200px] h-[48px] py-[12px] pl-[28px] pr-[32px] rounded-[12px] bg-#E5E7EB border-solid border-lightGray border-[1px] text-#111827 hover:bg-gray-100 cursor-pointer"
            >
              キャンセル
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={!isFormValid()}
              className={`block w-[200px] h-[48px] py-[12px] pl-[28px] pr-[32px] rounded-[12px] ${
                isFormValid()
                  ? "bg-#001CEC text-white hover:bg-#334BFC cursor-pointer"
                  : "bg-#E4E4E7 text-disableText cursor-not-allowed"
              } border-none focus:outline-none`}
            >
              更新する
            </button>
          </div>
        </div>
        <div className="w-[438px] min-h-screen  bg-#D1D5DB80">
          <h2 className="m-0 py-24px h-24px border-b-solid border-b-1px border-b-disableText text-center text-18px font-semibold text-#111827 font-hiragino">
            プレビュー
          </h2>
          <div className="flex flex-col gap-24px p-24px w-390px">
            <div className="mx-auto w-144px h-24px py-8px px-16px rounded-9999px bg-lightGray text-18px font-semibold text-secondary font-hiragino leading-6">
              クーポン詳細画面
            </div>
            <div className="flex flex-col gap-12px w-356px p-16px bg-#FFF rounded-12px">
              <div className="flex flex-col gap-8px w-358px break-all">
                {getShopByID?.shop?.name ? (
                  <div className="text-16px text-#111827 font-hiragino">
                    {getShopByID?.shop?.name}
                  </div>
                ) : (
                  <></>
                )}
                {getShopByID?.shop?.address ? (
                  <div className="text-14px text-#001EFB font-hiragino break-all">
                    {getShopByID?.shop?.address}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {couponName == "" ? (
                <></>
              ) : (
                <div className="text-20px text-#303846 font-hiragino  break-all">
                  {couponName}
                </div>
              )}
              {discountValue == "" ? (
                <></>
              ) : (
                <div>
                  <div className="flex gap-8px">
                    <div className="text-28px font-semibold text-#B91C1C font-hiragino break-all">
                      -{discountValue}
                      {discountType == "percentage_discount" ? "%" : "円"}
                    </div>
                    {originalPrice != "" && (
                      <div className="flex gap-5px">
                        <div className="flex">
                          <div className="text-28px font-semibold text-#111827 font-hiragino break-all">
                            {calculateDiscountedPrice()}
                          </div>
                          <div className="text-20px font-semibold text-#111827 font-hiragino leading-9">
                            円
                          </div>
                        </div>
                        <div className="text-16px text-#111827 font-hiragino leading-10">
                          税込
                        </div>
                      </div>
                    )}
                  </div>
                  {originalPrice != "" && (
                    <div className="text-16px text-#111827 font-hiragino line-through break-all">
                      (定価{originalPrice}円)
                    </div>
                  )}
                </div>
              )}
              {reason && (
                <div className="flex flex-col gap-4px">
                  <div className="text-14px font-semibold text-#111827 font-hiragino">
                    クーポン配布理由
                  </div>
                  <div
                    className="break-all"
                    dangerouslySetInnerHTML={{
                      __html: reason.replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
              )}
              {imagePreview ? (
                <div className="w-full aspect-[4/3] mx-auto rounded-8px overflow-hidden">
                  <img
                    src={imagePreview}
                    alt="Uploaded Preview"
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="mx-auto w-200px h-139px rounded-15px bg-lightGray text-center">
                  <span className="material-symbols-rounded pt-37px text-[60px] text-#4B556380 rounded-9999">
                    restaurant
                  </span>
                </div>
              )}
              {description && (
                <div
                  className="break-words"
                  dangerouslySetInnerHTML={{
                    __html: description.replace(/\n/g, "<br />"),
                  }}
                />
              )}
              <div className="text-16px text-#111827 font-hiragino">
                ※クーポンは商品の売れ行き次第で品切れになる場合がございます
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateTemplateCouponPage;
