import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({
  isVisible,
  toggleSidebar,
  environmentName,
  environmentColorClass,
}: {
  isVisible: boolean;
  toggleSidebar: () => void;
  environmentName: string;
  environmentColorClass: string;
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const location = useLocation();

  // パスを分割して最後のセグメントを取得
  const firstPathSegment =
    location.pathname.split("/").filter(Boolean).shift() || "";
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sharedProps = {
    firstPathSegment,
    isVisible,
    toggleSidebar,
    environmentName,
    environmentColorClass,
  };

  return isMobile ? (
    <MobileLoginPage {...sharedProps} />
  ) : (
    <DesktopLoginPage {...sharedProps} />
  );
};

interface SharedProps {
  firstPathSegment: string;
  isVisible?: boolean;
  toggleSidebar?: () => void;
  environmentName: string;
  environmentColorClass: string;
}

const MobileLoginPage: React.FC<SharedProps> = ({
  firstPathSegment,
  isVisible,
  toggleSidebar,
  environmentName,
  environmentColorClass,
}) => {
  return (
    <>
      {isVisible && (
        <div
          id="modal-overlay"
          onClick={toggleSidebar} // オーバーレイをクリックでサイドバーを閉じる
          className="fixed top-0 left-0 z-40 w-full h-full backdrop-blur-sm bg-surfaceOverlay"
        />
      )}
      <nav
        className={`fixed top-0 left-0 z-50 flex flex-col gap-16px w-[188px] h-screen p-[16px] border-[1px] border-r-lightGray border-r-solid bg-white transition-transform ${
          isVisible ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col gap-16px pb-16px border-b-solid border-b-1px border-b-lightGray">
          <div className="text-14px font-semibold text-secondary font-hiragino leading-5">
            管理コンソール
          </div>
          <div className="text-14px font-semibold text-secondary font-hiragino leading-5">
            ショップ用
          </div>
          {environmentName && (
            <>
              <p className="flex gap-4px my-0 py-[4px] px-[10px] h-[20px] rounded-[5px] bg-lightGray2 text-[14px] text-[#111827] font-hiragino leading-5">
                <span
                  className={`w-8px h-8px mt-6px rounded-9999px ${environmentColorClass}`}
                ></span>
                {environmentName}
              </p>
            </>
          )}
        </div>
        <div className="flex flex-col gap-24px">
          <div>
            <p className="mt-0 mb-[8px] text-[12px] text-secondary font-hiragino">
              クーポン
            </p>
            <ul
              style={{
                WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                outline: "none", // タップ時のアウトラインを無効化
              }}
              className="m-0 px-0"
            >
              <Link
                to="/"
                onClick={toggleSidebar}
                className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${
                  firstPathSegment === "" ||
                  firstPathSegment === "create_template_coupon" ||
                  firstPathSegment === "update_template_coupon"
                    ? "font-semibold text-#001EFB bg-accentPrimaryLight"
                    : "text-secondary"
                }`}
              >
                <div>
                  <span className="material-symbols-rounded">
                    confirmation_number
                  </span>
                </div>
                登録クーポン
              </Link>
            </ul>
            <ul
              style={{
                WebkitTapHighlightColor: "transparent",
                outline: "none",
              }}
              className="m-0 px-0"
            >
              <Link
                to="distributed_coupons"
                onClick={toggleSidebar}
                className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${
                  firstPathSegment === "distributed_coupons"
                    ? "font-semibold text-#001EFB bg-accentPrimaryLight"
                    : "text-secondary"
                }`}
              >
                <div>
                  <span className="material-symbols-rounded">send</span>
                </div>
                クーポン配布状況
              </Link>
            </ul>
          </div>
          <div className="">
            <p className="mt-0 mb-[5px] text-[12px] text-secondary font-hiragino">
              設定
            </p>
            <ul
              style={{
                WebkitTapHighlightColor: "transparent",
                outline: "none",
              }}
              className="m-0 px-0"
            >
              <Link
                to="/shop_setting"
                onClick={toggleSidebar}
                className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${
                  firstPathSegment === "shop_setting" ||
                  firstPathSegment === "shop_edit"
                    ? "font-semibold text-#001EFB bg-accentPrimaryLight"
                    : "text-secondary"
                }`}
              >
                <div>
                  <span className="material-symbols-rounded">storefront</span>
                </div>
                ショップ設定
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

const DesktopLoginPage: React.FC<SharedProps> = ({ firstPathSegment }) => {
  return (
    <>
      <nav className="fixed w-[188px] h-screen p-[16px] border-[1px] border-r-lightGray border-r-solid bg-#FFF">
        <div>
          <p className="mt-0 mb-[5px] text-[12px] text-secondary font-hiragino">
            クーポン
          </p>
          <ul className="m-0 px-0">
            <Link
              to="/"
              className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${firstPathSegment === "" || firstPathSegment === "create_template_coupon" || firstPathSegment === "update_template_coupon" ? "font-semibold text-#001EFB bg-accentPrimaryLight" : "text-secondary"}`}
            >
              <div>
                <span className="material-symbols-rounded">
                  confirmation_number
                </span>
              </div>
              登録クーポン
            </Link>
          </ul>
          <ul className="m-0 px-0">
            <Link
              to="distributed_coupons"
              className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${firstPathSegment === "distributed_coupons" ? "font-semibold text-#001EFB bg-accentPrimaryLight" : "text-secondary"}`}
            >
              <div>
                <span className="material-symbols-rounded">send</span>
              </div>
              クーポン配布状況
            </Link>
          </ul>
        </div>
        <div className="mt-[24px]">
          <p className="mt-0 mb-[5px] text-[12px] text-secondary font-hiragino">
            設定
          </p>
          <ul className="m-0 px-0">
            <Link
              to="/shop_setting"
              className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${firstPathSegment === "shop_setting" || firstPathSegment === "shop_edit" ? "font-semibold text-#001EFB bg-accentPrimaryLight" : "text-secondary"}`}
            >
              <div>
                <span className="material-symbols-rounded">storefront</span>
              </div>
              ショップ設定
            </Link>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
