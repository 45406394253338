export function encodeUUID(uuid: string) {
  // https://github.com/uuidjs/uuid/blob/4de23a6030e65ac72b3b015680f08e7e292681ed/src/parse.js#L3C1-L39C2
  function parse(uuid: string) {
    if (
      !(
        typeof uuid === "string" &&
        /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i.test(
          uuid
        )
      )
    ) {
      throw TypeError("Invalid UUID");
    }
    let v;
    const arr = new Uint8Array(16);
    // Parse ########-....-....-....-............
    arr[0] = (v = parseInt(uuid.slice(0, 8), 16)) >>> 24;
    arr[1] = (v >>> 16) & 0xff;
    arr[2] = (v >>> 8) & 0xff;
    arr[3] = v & 0xff;
    // Parse ........-####-....-....-............
    arr[4] = (v = parseInt(uuid.slice(9, 13), 16)) >>> 8;
    arr[5] = v & 0xff;
    // Parse ........-....-####-....-............
    arr[6] = (v = parseInt(uuid.slice(14, 18), 16)) >>> 8;
    arr[7] = v & 0xff;
    // Parse ........-....-....-####-............
    arr[8] = (v = parseInt(uuid.slice(19, 23), 16)) >>> 8;
    arr[9] = v & 0xff;
    // Parse ........-....-....-....-############
    // (Use "/" to avoid 32-bit truncation when bit-shifting high-order bytes)
    arr[10] = ((v = parseInt(uuid.slice(24, 36), 16)) / 0x10000000000) & 0xff;
    arr[11] = (v / 0x100000000) & 0xff;
    arr[12] = (v >>> 24) & 0xff;
    arr[13] = (v >>> 16) & 0xff;
    arr[14] = (v >>> 8) & 0xff;
    arr[15] = v & 0xff;
    return arr;
  }
  const toBase64 = (bytes: Uint8Array) => btoa(String.fromCharCode(...bytes));
  // https://github.com/taskcluster/slugid/blob/f6fa9ba5d4008d4cc02e4d360a103d81c6558dbe/slugid.js#L45-L53
  function encode(uuid: string) {
    const bytes = parse(uuid);
    const base64 = toBase64(bytes);
    const slug = base64
      .replace(/\+/g, "-") // Replace + with - (see RFC 4648, sec. 5)
      .replace(/\//g, "_") // Replace / with _ (see RFC 4648, sec. 5)
      .substring(0, 22); // Drop '==' padding
    return slug;
  }
  return encode(uuid);
}

export function getQRURL(serviceId: string, slug: string) {
  const u = encodeURIComponent(`/transactions/${slug}`);

  return `https://p8n.jp/i/${encodeUUID(serviceId)}?u=${u}`;
}
