import * as jose from "jose";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { tokenAtom } from "../../util/atoms";

export type OutletContext = {
  token: string;
};

export default function ProtectedLayout() {
  const [token, setToken] = useAtom(tokenAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      return;
    }

    const data = jose.decodeJwt(token);

    if (!data.exp) {
      throw new Error("exp is undefined");
    }

    if (data.exp * 1000 < Date.now()) {
      setToken(null);
      navigate("/login");
    }
  }, [token, setToken]);

  if (!token) {
    navigate("/login");
    return <></>;
  }

  return (
    <>
      <Outlet context={{ token } satisfies OutletContext} />
    </>
  );
}
