import { Outlet, useOutletContext } from "react-router-dom";

import Header from "../organisms/header";
import Sidebar from "../organisms/sidebar";
import { OutletContext } from "./protectedLayout";
import { useEffect, useMemo, useState } from "react";

const ConsoleLayout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const { token } = useOutletContext<OutletContext>();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const { environmentName, environmentColorClass } = useMemo(() => {
    const hostname = window.location.hostname;
    if (hostname === "localhost")
      return {
        environmentName: "ローカル環境",
        environmentColorClass: "bg-gray-400",
      };
    if (hostname.endsWith("mock.pocketsign.dev"))
      return {
        environmentName: "開発環境",
        environmentColorClass: "bg-[#EE793F]",
      };
    if (hostname.endsWith("mock.pocketsign.app"))
      return {
        environmentName: "デモ環境",
        environmentColorClass: "bg-[#059669]",
      };
    return { environmentName: "", environmentColorClass: "" };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="bg-#F3F4F6 font-hiragino text-#111827">
        <Header
          toggleSidebar={toggleSidebar}
          environmentName={environmentName}
          environmentColorClass={environmentColorClass}
        />
        <div className="h-[52px]"></div>
        <Sidebar
          isVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar}
          environmentName={environmentName}
          environmentColorClass={environmentColorClass}
        />
        <div className={`min-h-screen ${!isMobile && "ml-[220px]"}`}>
          <Outlet context={{ token } satisfies OutletContext} />
        </div>
      </div>
    </>
  );
};

export default ConsoleLayout;
